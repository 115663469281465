/* 패키지 공통 */
.section-package {
	.package-data {
		margin-top: 10px;

		li {
			padding-left: 8px;
			font-weight: 300;
			font-size: 14px;
			color: #232323;
			position: relative;

			&:not(:first-child) { margin-top: 3px; }

			&:before {
				content: '';
				display: block;
				width: 2px;
				height: 2px;
				background: #232323;
				position: absolute;
				top: 9px;
				left: 0;
			}
		}
	}
}