/* 이벤트 상세 */
.event-detail-photo {

	.detail-header {
		padding: 0 10px 15px;
		border-bottom: 1px solid #ddd;

		.title {
			line-height: 1.3;
			font-weight: 500;
			font-size: 18px;
			color: #232323;
			word-wrap: break-word;
		}

		.info {
			margin-top: 3px;
			font-weight: 300;
			font-size: 13px;
			color: #666;
		}
	}

	.detail-content {
		padding: 15px 0;
		font-weight: 300;
		word-wrap: break-word;
		border-bottom: 1px solid #ddd;

		img { max-width: 100%; }

		.txt-area {
			padding: 0 10px;
		}
	}

	.photo-event-img {
		margin-top: 25px;
		text-align: center;

		.img {
			&:not(:first-child) { margin-top: 5px; }
		}

		img {
			max-width: 100%;
		}
	}
}