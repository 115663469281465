/* 공통 약관 팝업 스타일 */
.pop-com-license {
	.common-pop-content {
		font-size: 13px;
	}

	.license-txt {
		box-sizing: border-box;
		height: 130px;
		padding: 10px;
		border: 1px solid #ddd;
		overflow: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
	}

	/* 약관 제목 depth-1 */
	.license-tit-1 {
		font-weight: 500;
		color: #666;

		&:not(:first-child) { margin-top: 15px; }

		& + .license-p,
		& + .license-list { margin-top: 0; }
	}

	/* 약관 내용 문단 */
	.license-p {
		margin: 5px 0;
		color: #666;
	}

	/* 약관 내용 목록 */
	.license-list {
		margin: 5px 0;
		color: #666;

		> li .license-list {
			padding-left: 20px;

			> li .license-list {
				padding-left: 20px;
			}
		}
	}

	/* 기타 설명 박스 */
	.desc-box {
		margin-top: 15px;
		padding: 12px;
		font-size: 12px;
		background: #f5f5f5;
	}
}