/* 객실 이용안내 */
.room-guide {
	&-list {
		margin: -#{$com-mt} -#{$com-ml} 0;
		margin: -#{$com-mt} safe(left,-$com-ml) 0;
		border-bottom: 1px solid #eee;

		> dt {
			position: relative;
			font-weight: 300;
			color: #232323;

			&:not(:first-child) {
				border-top: 1px solid #eee;
			}

			&:before {
				content: '';
				display: block;
				width: 1px;
				background: #ccc;
				position: absolute;
				top: 15px;
				bottom: 15px;
				left: 70px;
			}

			a {
				display: block;
				padding: 14px 30px 14px 85px;
				padding: 14px safe(right,30px) 14px safe(left,85px);
				word-wrap: break-word;
				text-decoration: none;
				position: relative;

				&:before {
					content: '';
					display: block;
					position: absolute;
					top: calc(50% - 20px);
					left: 10px;
					left: safe(left,10px);
				}

				&:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					right: $com-mr;
					right: safe(right,$com-mr);
					margin-top: -4px;

					@include sprite-2x($ico-accordion-off);
				}
			}

			&.is-active {
				font-weight: 500;

				a {
					&:after {
						@include sprite-2x($ico-accordion-on);
					}
				}

				& + dd {
					display: block;
				}
			}

			.num {
				display: block;
				font-weight: 500;
				font-size: 12px;
				color: #b0895c;
			}

			.tit {
				display: block;
				font-weight: 500;
				font-size: 15px;
				color: #232323;
			}
		}

		.icon1 a:before { @include sprite-2x($room-guide1); }
		.icon2 a:before { @include sprite-2x($room-guide2); }
		.icon3 a:before { @include sprite-2x($room-guide3); }
		.icon4 a:before { @include sprite-2x($room-guide4); }
		.icon5 a:before { @include sprite-2x($room-guide5); }
		.icon6 a:before { @include sprite-2x($room-guide6); }
		.icon7 a:before { @include sprite-2x($room-guide7); }
		.icon8 a:before { @include sprite-2x($room-guide8); }
		.icon9 a:before { @include sprite-2x($room-guide9); }

		> dd {
			display: none;
			font-weight: 300;
			font-size: 13px;
			color: #232323;
			border-top: 1px solid #ddd;
			padding: 15px $com-ml;
			padding: 15px safe(left,$com-ml);
			position: relative;
			word-wrap: break-word;

			p, ul {
				margin: 5px 0;
			}
		}

		strong.line { text-decoration: underline; }
	}
}