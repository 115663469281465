//
// 결제 FORM 페이지에서 공통 사용하는 유형
// --------------------------------------------------

.option-grid {
	display: flex;
	flex-wrap: wrap;
	border-top: 1px solid #232323;
	border-bottom: 1px solid #ddd;

	label {
		flex: 1;
		max-width: 50%;
		padding: 10px 5px;
	}

	.row {
		width: 100%;
		&:not(:first-child) { border-top: 1px solid #ddd; }
	}
}

.card-select {
	display: flex;
	padding: 10px 5px;
	line-height: 28px;
	border-bottom: 1px solid #ddd;

	select {
		flex: 1;
		margin: 0 5px 0 0;
	}
}

/* 최종 결제 정보 */
.final-pay-info {
	border-top: 1px solid #232323;

	dl {
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		padding: 9px 10px;
		font-size: 14px;
		font-weight: 500;
		border-bottom: 1px solid #ddd;
	}

	dt {
		color: #666;
	}

	dd {
		text-align: right;
		font-weight: 500;
		color: #232323;
	}

	.total {
		background: #f5f5f5;

		dd {
			font-weight: 500;
			color: #d14e40;
		}
	}

	/* 패키지예약 결제정보 구분 */
	.pay-opt {
		padding: 7px 0;
		border-bottom: 1px solid #ddd;

		dl { 
			padding: 3px 10px;
			border-bottom: 0;
		}

		dt { font-weight: 300; }
	}
}

/* 결제 위약규정 동의 */
.pay-foot-agree {
	margin-top: 10px;
	font-weight: 300;
	color: #232323;

	label {
		display: block;
	}
}