/* 객실 예약 변경 신청 */
.mypage-room-change {
	.foot-desc {
		margin-top: 15px;
		text-align: center;
		font-weight: 300;
		font-size: 12px;
		color: #b08a5d;
	}

	.option-apply {
		margin-top: 15px;
		text-align: center;
	}
}