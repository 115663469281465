/* 워터파크 실내존/실외존  */
.waterpark-zone {
	.common-basic-info {
		background: #268dc8;
	}

	/* 주의사항 픽토그램 리스트 */
	.attention-list {

		li {
			display: flex;
			align-items: center;
			padding: 7px 0;
			font-size: 13px;
			border-top: 1px solid rgba(215,215,215,.2);
		}

		.ico {
			flex-shrink: 0;
			display: flex;
			justify-content: flex-end;
			width: 30%;
			height: 35px;
			margin-right: 22px;
			position: relative;

			&:before {
				content: '';
				display: block;
			}

			&.ico1:before { @include sprite-2x($waterpark-attention1); }
			&.ico2:before { @include sprite-2x($waterpark-attention2); }
			&.ico3:before { @include sprite-2x($waterpark-attention3); }
			&.ico4:before { @include sprite-2x($waterpark-attention4); }
			&.ico5:before { @include sprite-2x($waterpark-attention5); }
			&.ico6:before { @include sprite-2x($waterpark-attention6); }
			&.ico7:before { @include sprite-2x($waterpark-attention7); }
			&.ico8:before { @include sprite-2x($waterpark-attention8); }
			&.ico9:before { @include sprite-2x($waterpark-attention9); }
			&.ico10:before { @include sprite-2x($waterpark-attention10); }
			&.ico11:before { @include sprite-2x($waterpark-attention11); }
			&.ico12:before { @include sprite-2x($waterpark-attention12); }
			&.ico13:before { @include sprite-2x($waterpark-attention13); }
			&.ico14:before { @include sprite-2x($waterpark-attention14); }
		}

	}
}