/* 휘닉스스토리 */
.company-phoenix-story {

	.intro-wrap {
		padding-bottom: 40px;
		border-bottom: 1px solid #ddd;
	}

	.top-intro {
		&:before {
			content: '';
			display: block;
			margin: -20px -#{$com-ml} 0;
			margin: -20px safe(left,-$com-ml) 0;
			padding-top: 80%;
			background: url('@@img/company/photo-pheonix-story.jpg');
			background-size: cover;
		}
	}
	
	.sub-intro {
		&.story1:before {
			content: '';
			display: block;
			padding-top: 56.25%;
			background: url('@@img/company/phoenix-story-content1.jpg');
			background-size: cover;
		}
		&.story2:before {
			content: '';
			display: block;
			padding-top: 56.25%;
			background: url('@@img/company/phoenix-story-content2.jpg');
			background-size: cover;
		}
	}

	.head-copy {
		margin-top: 30px;
		line-height: 1.4;
		font-weight: 500;
		font-size: 18px;
		color: #232323;
	}

	.sub-copy {
		text-align: center;
		line-height: 1.4;
		font-weight: 500;
		font-size: 16px;
		color: #232323;
	}

	.con { margin: 25px 0 40px; }

	.video-area {
		margin-top: 15px;
		img { width: 100%; }
	}

}