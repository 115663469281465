/* 평창 동계올림픽 */
.snowpark-olympic-winter {
	
	/* 앰블럼 안내 */
	.head-title {
		margin: 30px 0 15px;
		color: #232323;
		font-size: 22px;
		font-weight: 500;
		text-align: center;
		line-height: 1.3;
	}

	.pyeongchang-olympic {
		.img {
			img { width: 100%; }
		}

		.detail-wrap:not(:last-child) { padding-bottom: 50px; }
	}

	/* 올림픽 코스 */
	.olympic-course-intro {
		padding-bottom: 25px;

		.thumb {
			margin-bottom: 20px;
			padding-top: 71%;
		}
		.btn-area { text-align: center; }
	}

}