/* 나의정보관리 */
.mypage-myinfo {
	.myinfo-login {
		padding: 25px 15px;
		background: #f5f5f5;
	}

	/* 가족/지정회원 정보관리 */
	.family-list {
		.btn-list-action {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translate(0,-50%);
		}

		.state {
			font-size: 11px;
			color: #232323;
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translate(0,-50%);
		}
		.ing {
			color: #d14e40;
		}
	}

	/* 비밀번호 변경 */
	.toggle-password-change {
		display: inline-block;
		margin-top: 5px;
		font-size: 12px;
		color: #b0895c;
		text-decoration: none;

		&:after {
			content: '';
			display: inline-block;
			margin-left: 5px;
			vertical-align: middle;

			@include sprite-2x($ico-toggle-arw-off);
		}
	}

	.password-wrap {
		&.is-active {
			.toggle-password-change:after {
				@include sprite-2x($ico-toggle-arw-on);
			}

			.password-change {
				display: block;
			}
		}
	}

	.password-change {
		display: none;

		.desc {
			color: #999;
		}
		.main-desc {
			font-size: 12px;
			color: #666;
		}
	}
}