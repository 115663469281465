/* 건축아트 아고라 */
.architecture-agora {
	.common-basic-info {
		background: #42a0a9;
	}
	
	.main-info {
		padding: 25px 0 20px;

		.title {
			margin-bottom: 7px;
			line-height: 1.3;
			font-size: 18px;
			color: #232323;
			letter-spacing: -.05em;
		}
	}

	.only-member {
		padding: 10px 0;
		text-align: center;
		background: #b0895c;
		font-weight: 500;
		font-size: 14px;
		color: #fff;
	}

	.architect-intro {
		margin-top: 20px;
	}
}