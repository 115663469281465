/* 교통 안내 */
.guide-traffic-map-jeju {
	margin: -#{$com-mt} $com-ml -#{$com-mb};
	margin: -#{$com-mt} safe(left,$com-ml) -#{$com-mb};

	.map-area {
		margin: 0 -#{$com-ml};
		margin: 0 safe(left,-$com-ml);
	}
	
	.head-title {
		margin: 30px 0 20px;
		color: #232323;
		font-size: 18px;
		font-weight: 500;
		text-align: center;
	}

	.traffic-content {
		.table-container {
			border-left: 1px solid #e8e8e8;
			border-right: 1px solid #e8e8e8;
		}

		.sub-title {
			padding-left: 5px;
			color: #d86719;
			font-weight: 500;
			&:not(:first-child) { margin-top: 8px; }
		}
	}

	.navi-img {		
		padding: 0 5px;
		img { width: 100%; }
	}

	.navi-list {
		margin: 15px 0 20px;
		padding: 0 5px;

		li {
			padding-left: 25px;
			position: relative;

			&:not(:first-child) { margin-top: 18px; }
		}
		
		.title {
			color: #d86719;
			font-weight: 500;
		}

		.num {
			display: inline-block;
			width: 18px;
			height: 18px;
			line-height: 18px;
			text-align: center;
			font-size: 8px;
			color: #fff;
			background: #232323;
			border-radius: 100%;
			position: absolute;
			top: 2px;
			left: 0;
		}
	}
	
	.rentcar-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.item {
			width: calc((100% - 10px) / 2);
			text-align: center;
		}

		.title {
			color: #232323;
			font-size: 16px;
			font-weight: 500;
		}

		.thumb {
			margin-top: 10px;
			padding-top: 56.25%;
			background-size: cover;
		}

		.btn-area {
			margin-top: 15px;

			.btn { width: 105px; }
		}
	}
}