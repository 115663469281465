/* 시즌권 인증 관련 팝업 */
.pop-ticket-auth {

	.content-title {
		margin-bottom: 15px;
		font-weight: 500;
		text-align: center;
		font-size: 20px;
		color: #232323;
	}

	.scroll-wrap {
		overflow: scroll;
		overflow-x: hidden;
	}

	.img-desc {
		margin-top: 10px;
		
		img { width: 100%; }
	}
}