/* 오시는길 */
.company-map {
	.map-area {
		margin: -20px -#{$com-ml} 0;
		margin: -20px safe(left,-$com-ml) 0;
	}

	.common-basic-info {
		background: #4b6781;

		.top-desc {
			margin-top: 10px;
		}
	}
} 