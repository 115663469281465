/* HNR 메인 */
.main-hnr {
	.promotion {
		padding: 35px 0 20px;
		color: #232323;
		background: #f7f7f7;
	}

	.brand-story {
		padding: 35px $com-ml 45px;
		padding: 35px safe(left,$com-ml) 45px;
		color: #fff;
		background: #002d4e;

		.link {
			display: block;
			text-decoration: none;
		}

		.bg {
			padding-top: 72.4%;
			background: url('@@img/main/hnr/brand-story.jpg') no-repeat 0 0 / cover;
		}

		.con {
			text-align: center;
		}

		.copy {
			margin-top: 25px;
			line-height: 1.4;
			font-weight: 500;
			font-size: 20px;
		}

		.desc {
			margin-top: 12px;
			font-weight: 300;
			font-size: 14px;
			color: #c0c0c0;
		}
	}

	.food-story {
		padding: 35px $com-ml 45px;
		padding: 35px safe(left,$com-ml) 45px;
		color: #232323;
		background: #f5f3ee;

		.link {
			display: block;
			text-decoration: none;
		}

		.bg {
			padding-top: 72.4%;
			background: url('@@img/main/hnr/food-story.jpg') no-repeat 0 0 / cover;
		}

		.con {
			text-align: center;
		}

		.copy {
			margin-top: 25px;
			line-height: 1.4;
			font-weight: 500;
			font-size: 20px;
		}

		.desc {
			margin-top: 12px;
			font-weight: 300;
			font-size: 14px;
			color: #666;
		}
	}

	.quick-link {
		padding: 35px $com-ml 45px;
		padding: 35px safe(left,$com-ml) 45px;
		color: #232323;

		.list {
			display: flex;
			justify-content: space-around;

			li {
				&:not(:first-child) {
					margin-left: 5%;
				}
			}

			a {
				display: block;
				text-align: center;
				line-height: 1.3;
				font-size: 13px;
				text-decoration: none;

				&:before {
					content: '';
					display: block;
					width: 63px;
					height: 63px;
					margin: 0 auto 14px;
					background-size: cover;
				}

				&.condo1:before { background-image: url('@@img/main/hnr/membership1.png'); }
				&.condo2:before { background-image: url('@@img/main/hnr/membership2.png'); }
				&.golf1:before { background-image: url('@@img/main/hnr/membership3.png'); }
				&.golf2:before { background-image: url('@@img/main/hnr/membership4.png'); }
			}

			span {
				font-weight: 500;
				color: #232323;
			}
		}
	}
}