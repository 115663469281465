/* 나의 예약/구매 상세 */
.mypage-reservation-detail {

	.foot-state-desc {
		padding: 25px 0;
		text-align: center;
		font-weight: 300;
		font-size: 14px;
		border-bottom: 1px solid #ddd;

		.complete-msg {
			color: $point;
			font-size: 14px;
			font-weight: 500;
		}
	}

}