/* 회원가입 공통 */
.section-join {

	/* 회원가입 스탭 */
	.step-join {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		margin: -#{$com-mt} -#{$com-ml} $com-mt;
		background: #f5f5f5;

		li {
			display: flex;
			position: relative;

			&:not(:last-child) {
				padding-right: 27px;

				&:before {
					content: '';
					display: block;
					width: 10px;
					height: 19px;
					background: url('@@img/join/join-step-arw.png') no-repeat;
					background-size: 10px 19px;
					position: absolute;
					top: calc(50% - 10px);
					right: 7px;
				}
			}
		}

		.step {
			box-sizing: border-box;
			display: block;
			width: 24px;
			height: 24px;
			line-height: 22px;
			margin-right: 4px;
			text-align: center;
			font-weight: 700;
			font-size: 10px;
			color: #999;
			background: #fff;
			border: 1px solid #ddd;
			border-radius: 22px;
		}

		.title {
			line-height: 24px;
			font-weight: 500;
			font-size: 13px;
			color: #999;
			letter-spacing: -.075em;
			white-space: nowrap;
		}

		.current {
			.step {
				color: #fff;
				background: #232323;
				border-color: #232323;
			}

			.title {
				color: #232323;
			}
		}
	}

	.license-container {
		padding: 15px 0;
		color: #232323;
		border-bottom: 1px solid #ddd;

		&.single { padding: 0; }
	}

	.main-form {
		padding: 14px 0 20px;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #ddd;
	}

	.form-head {
		width: 80px;
	}

	// .step-join {
	// 	$size: 75px;

	// 	display: flex;
	// 	justify-content: space-between;
	// 	margin-bottom: 20px;
	// 	text-align: center;
	// 	font-size: 0;
	// 	position: relative;

	// 	&:before {
	// 		content: '';
	// 		display: block;
	// 		width: 100%;
	// 		height: 1px;
	// 		background: #ddd;
	// 		position: absolute;
	// 		top: 37px;
	// 		left: 0;
	// 	}

	// 	li {
	// 		display: inline-block;
	// 		width: $size;
	// 		position: relative;

	// 		&.step1 .step { background-image: url('@@img/join/join-step1-off.png'); }
	// 		&.step2 .step { background-image: url('@@img/join/join-step2-off.png'); }
	// 		&.step3 .step { background-image: url('@@img/join/join-step3-off.png'); }

	// 		&.current {
	// 			&.step1 .step { background-image: url('@@img/join/join-step1-on.png'); }
	// 			&.step2 .step { background-image: url('@@img/join/join-step2-on.png'); }
	// 			&.step3 .step { background-image: url('@@img/join/join-step3-on.png'); }

	// 			.title {
	// 				color: #422121;
	// 			}
	// 		}
	// 	}

	// 	.step {
	// 		display: block;
	// 		width: $size;
	// 		max-height: $size;
	// 		padding-top: $size;
	// 		background-size: $size $size;
	// 		background-repeat: no-repeat;
	// 		overflow: hidden;
	// 	}

	// 	.title {
	// 		display: block;
	// 		margin-top: 8px;
	// 		font-weight: 500;
	// 		font-size: 13px;
	// 		color: #999;
	// 	}
	// }
}