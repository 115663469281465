/* 약관 팝업 */
.pop-license {

	/* 약관 제목 depth-1 */
	.license-tit-1 {
		font-weight: 500;
		font-size: 14px;
		color: #666;

		&:not(:first-child) { margin-top: 20px; }
	}

	/* 약관 내용 문단 */
	.license-p {
		margin: 5px 0;
		font-weight: 300;
		font-size: 14px;
		color: #666;
	}

	/* 약관 내용 목록 */
	.license-list {
		margin: 5px 0;
		font-size: 14px;
		color: #666;

		> li .license-list {
			padding-left: 20px;

			> li .license-list {
				padding-left: 20px;
			}
		}
	}
}