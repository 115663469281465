/* 행사웨딩 - 학생단체프로그램 */
.party-student-program {

	.common-basic-info {
		background: #2e4b65;

		& + .page-foot2 {
			margin-top: 0;
			border-top: 0;
		}
	}

	.certification-program {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		padding: 20px 10px;
		background: #f5f5f5;

		&:before {
			content: '';
			flex-shrink: 0;
			display: block;
			width: 65px;
			height: 65px;
			margin-right: 10px;
			background: url('@@img/party/pyeongchang/student-mark.jpg') no-repeat 0 0 / 65px 65px;
		}
	}

	.detail-title {
		text-align: center;
		font-weight: 500;
		font-size: 18px;
		color: #232323;
		letter-spacing: -.05em;
	}

	.activity {
		padding: 15px;
		background: #f5f5f5;

		&:before { display: none; }

		dt {
			display: inline-block;
			width: 60px;
			height: 20px;
			text-align: center;
			font-weight: 500;
			font-size: 14px;
			color: #fff;
			background: #006097;
			border-radius: 10px;
		}

		dd {
			display: block;
			padding-left: 10px;
			position: relative;

			&:not(:first-child) { margin-top: 5px; }

			&:before {
				content: '';
				display: block;
				width: 2px;
				height: 2px;
				background: #666;
				position: absolute;
				top: .7em;
				left: 2px;
			}
		}
	}

	.hygiene-list {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 15px -#{$com-ml} 0;
		margin: 15px safe(left,-$com-ml) 0;
		padding: 25px 10px 20px;
		padding: 25px safe(left,10px) 20px;
		background: #f5f5f5;

		li {
			width: calc((100% - 30px) / 3);
			margin: 0 5px;
			padding-bottom: 10px;
			text-align: center;
		}

		.thumb {
			img { width: 100%; }
		}

		.tit {
			display: block;
			margin-top: 5px;
			font-size: 11px;
			letter-spacing: -.05em;
		}

		strong {
			display: block;
			font-weight: 500;
			font-size: 13px;
			color: #232323;
		}
	}
}