/* 예약 완료 */
.reservation-complete {
	
	.foot-desc {
		margin-top: 15px;
		text-align: center;
		font-weight: 300;
		font-size: 13px;
		color: #666;
	}
}