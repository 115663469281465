/* 가입완료 */
.join-ok {

	.main-box {
		padding: 25px 0;
		text-align: center;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #ddd;
	}

	.main-msg {
		margin-bottom: 15px;
		line-height: 22px;
		font-weight: 300;
		font-size: 17px;
		color: #000;
		letter-spacing: -.05em;
	}

	.detail {
		padding: 15px 0;
		background: #f8f8f8;

		dl {
			display: flex;
		}

		dt {
			display: block;
			width: 50%;
			padding-right: 10px;
			text-align: right;
		}
		
		dd {
			box-sizing: border-box;
			display: block;
			width: 50%;
			text-align: left;
		}
	}
}