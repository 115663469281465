/* CI&BI */
.company-identity {

	.identity-con {
		.bg {
			max-width: 350px;
			margin: 0 auto;
		}

		p { margin-top: 15px; padding: 0 5px; }
		
		&.logo-concept .bg:before {
			content: '';
			display: block;
			padding-top: 61%;
			background: url('@@img/company/logo-concept.jpg') no-repeat 50% 0 / contain;
		}

		&.brand-structure .bg:before {
			content: '';
			display: block;
			padding-top: 77%;
			background: url('@@img/company/brand-structure.jpg') no-repeat 50% 0 / contain;
		}
	}

	.bi-title {
		position: relative;
		margin-top: 20px;
		margin-left: 5px;
		padding-left: 8px;
		color: #232323;
		font-size: 14px;
		font-weight: 500;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: calc(50% - 1px);
			left: 0;
			width: 2px;
			height: 2px;
			background: #666;
		}
	}

	.bi-box {
		height: 110px;
		margin-top: 10px;
		border: 1px solid #d9d8d6;
		display: flex;
		align-items: center;
		justify-content: center;
		
		img { height: 49px; }
	}

	.service-bi-list {
		border-top: 1px solid #d9d8d6;
		border-bottom: 1px solid #d9d8d6;
		
		.con {
			padding: 18px 0;
			display: flex;
			align-items: center;

			&:not(:first-child) { border-top: 1px solid #d9d8d6; }
		}

		.tit {
			width: 65px;
			font-weight: 400;
			text-align: center;
		}

		ul {
			border-left: 1px solid #d9d8d6;
		}

		li {
			padding-left: 20px;
			&:not(:first-child) { margin-top: 18px; }

			img { height: 23px; }
		}
	}

}
