/* 골프 - 코스공략 */
.golf-course {
	.info-area {
		.title {
			font-weight: 500;
			font-size: 16px;
			color: #232323;
		}

		.sub-info { font-weight: 400; }

		.control {
			margin-top: 20px;
			text-align: center;

			.btn + .btn { margin-left: 5px; }
		}
	}

	.tee-list {
		display: flex;
		flex-wrap: wrap;
		margin-top: 15px;

		li {
			display: flex;
			align-items: center;
			width: 50%;
			font-size: 13px;

			&:before {
				content: ' ';
				box-sizing: border-box;
				display: block;
				width: 12px;
				height: 12px;
				margin-right: 5px;
				border: 1px solid #e5e5e5;
				background: #fff;
				border-radius: 6px;
			}

			&.gold:before { background: #ffc704; border-color: #ffc704; }
			&.blue:before { background: #006097; border-color: #006097; }
			&.white:before { background: #fff; border-color: #e5e5e5; }
			&.red:before { background: #dd0101; border-color: #dd0101; }
		}
	}

	.hole-area {
		margin: 20px -#{$com-ml};
		margin: 20px safe(left,-$com-ml);
		img { width: 100%; }
	}

	.hole-tip {
		dt {
			display: block;
			line-height: 1.3;
			font-weight: 500;
			font-size: 16px;
			color: #232323;
		}
		dd {
			display: block;
			margin-top: 5px;
		}
	}
}