/* 약관동의 */
.join-agree {

	.agree-all {
		padding: 0 5px;
		line-height: 44px;
		font-weight: 500;
		color: #232323;
		letter-spacing: -.05em;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #232323;
	}

	.option {
		margin-top: 15px;
		padding: 15px 5px 0;
		font-weight: 300;
		border-top: 1px solid #ddd;

		.tit { margin-bottom: 5px; }

		label { font-weight: 500; }
	}
	
}