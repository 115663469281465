/* 시즌 영업 일정 */
.snowpark-season-schedule {
	
	.head {
		margin-bottom: 20px;
		text-align: center;
		position: relative;
	}

	.year-month {
		line-height: 1;
		font-weight: 500;
		font-size: 18px;
		color: #232323;
	}

	.btn-prev {
		display: block;
		top: -4px;
		left: 0;

		@include afterSpriteIR-2x($snowpark-calendar-prev, 30px, 30px, $position:absolute);
	}

	.btn-next {
		display: block;
		top: -4px;
		right: 0;

		@include afterSpriteIR-2x($snowpark-calendar-next, 30px, 30px, $position:absolute);
	}

	.schedule-list {
		border: 1px solid #ddd;

		> li {
			display: flex;
			flex-direction: row;
			align-items: center;
			background: #fff;
			

			&:not(:first-child) { border-top: 1px solid #ddd; }


			&.disabled { background: #f5f5f5; }

			&.sun {
				.day { color: #d14e40; }
			}
			&.sat {
				.day { color: #0078be; }
			}
		}

		.day {
			width: 50px;
			padding: 20px 0;
			border-right: 1px solid #ddd;
			color: #232323;
			font-size: 10px;
			text-align: center;

			span {
				font-size: 15px;
				font-weight: 700;
			}
		}
	}
	
	.schedule {
		display: flex;
		flex: 1;
		flex-direction: row;

		.item {
			width: 20%;
			font-size: 13px;
			font-weight: 500;
			padding-left: 3%;

			&:before {
				content: '';
				display: inline-block;
				width: 10px;
				height: 10px;
				border-radius: 100%;
				margin-right: 4px;
				background: #aaa;
				vertical-align: 0;
			}

			&.is-daytime-am {
				color: #4ba727;
				&:before { background: #4ba727; }
			}
			&.is-daytime-pm {
				color: #cf5d16;
				&:before { background: #cf5d16; }
			}
			&.is-night {
				color: #01609a;
				&:before { background: #01609a; }
			}
			&.is-midnight {
				color: #07304c;
				&:before { background: #07304c; }
			}
			&.is-whitenight {
				color: #999;
				&:before { background: #999; }
			}
		}
	}

	.schedule-info {
		margin-bottom: 15px;

		.item {

			&:not(:first-child) { margin-top: 5px; }
			
			span {
				display: inline-block;
				width: 65px;
				font-weight: 500;

				&:before {
					content: '';
					display: inline-block;
					width: 12px;
					height: 12px;
					border-radius: 100%;
					margin-right: 6px;
					background: #aaa;
					vertical-align: 0;
				}

				&.is-daytime-am {
					color: #4ba727;
					&:before { background: #4ba727; }
				}
				&.is-daytime-pm {
					color: #cf5d16;
					&:before { background: #cf5d16; }
				}
				&.is-night {
					color: #01609a;
					&:before { background: #01609a; }
				}
				&.is-midnight {
					color: #07304c;
					&:before { background: #07304c; }
				}
				&.is-whitenight {
					color: #999;
					&:before { background: #999; }
				}
			}
		}
	}

}