/* 고객센터 게이트 */
.customer-index {

	.faq-category {
		margin: 0 -#{$com-ml};
		margin: 0 safe(left,$com-ml);
		padding: 16px $com-ml 20px;
		padding: 16px safe(left,$com-ml) 20px;
		background: #f4f4f4;

		h4 {
			margin-bottom: 5px;
			padding: 0 5px;
			font-weight: 500;
			font-size: 13px;
			color: #b0895c;

			&:not(:first-child) { margin-top: 15px; }
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			background: #fff;
			box-shadow: inset 0 0 0 1px #ddd;
			position: relative;
		}

		li {
			flex: 1 0 calc(100% / 3);
			max-width: calc(100% / 3);
		}

		a {
			box-sizing: border-box;
			display: block;
			height: 35px;
			line-height: 35px;
			text-align: center;
			font-size: 13px;
			color: #666;
			text-decoration: none;
			position: relative;
			border-bottom: 1px solid #ddd;
			border-right: 1px solid #ddd;
		}
	}

	.inquiry-link {
		display: flex;
		border: 1px solid #ddd;

		li {
			flex: 1;

			& + li { border-left: 1px solid #ddd; }

			&:first-child a { background-image: url('@@img/customer/logo-pyeongchang.png'); }
			&:last-child a { background-image: url('@@img/customer/logo-jeju.png'); }
		}

		a {
			box-sizing: border-box;
			display: block;
			height: 65px;
			padding-top: 34px;
			text-align: center;
			font-weight: 500;
			font-size: 13px;
			color: #232323;
			text-decoration: none;
			background-repeat: no-repeat;
			background-size: 35px 14px;
			background-position: 50% 16px;
		}
	}

	.tel-guide-link {
		display: flex;
		border: 1px solid #ddd;

		li {
			flex: 1;

			& + li { border-left: 1px solid #ddd; }
		}

		a {
			box-sizing: border-box;
			display: block;
			height: 38px;
			line-height: 38px;
			text-align: center;
			font-size: 13px;
			color: #666;
			text-decoration: none;
		}
	}

	.notice {
		margin-bottom: 40px;

		.link {
			display: flex;
			box-sizing: border-box;
			height: 50px;
			line-height: 50px;
			text-decoration: none;
			border-top: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
		}

		.head {
			padding: 0 17px 0 5px;
			font-weight: 500;
			font-size: 16px;
			color: #232323;
		}

		.title {
			flex: 1;
			padding-right: 20px;
			position: relative;

			@include ellipsis;

			&:after {
				content: '';
				display: block;
				width: 9px;
				height: 16px;
				background: url('@@img/customer/notice-link.png') no-repeat;
				background-size: 9px 16px;
				position: absolute;
				top: calc(50% - 8px);
				right: 10px;
			}
		}
	}
}