/* 로그인 공통 */
.section-login {

	.msg-area {
		margin-bottom: 16px;
		text-align: center;

		.copy {
			line-height: 1.3;
			font-size: 17px;
			color: #232323;
		}

		.desc {
			margin-top: 5px;
			line-height: 1.3;
			font-weight: 300;
			font-size: 14px;
			color: #666;
		}
	}

	.msg-area2 {
		margin-top: 16px;
		line-height: 1.3;
		text-align: center;
		font-size: 16px;
		color: #232323;
	}

	/* 휴대폰 인증 안내 */
	.auth-msg {
		margin-top: 25px;
		text-align: center;
		line-height: 1.3;
		font-weight: 300;

		&:before {
			content: '';
			display: block;
			width: 90px;
			height: 90px;
			margin: 0 auto 12px;
			background: url('@@img/login/auth-phone.png') no-repeat 50% 0;
			background-size: 90px 90px;
		}
	}

	// /* 인증 방법 선택 */
	// .auth-type {
	// 	display: flex;
	// 	justify-content: space-between;
	// 	max-width: 335px;
	// 	margin: 0 auto;
	// }

	// /* 인증 방법 선택 */
	// .select-block {
	// 	box-sizing: border-box;
	// 	display: block;
	// 	width: calc(50% - 5px);
	// 	height: 170px;
	// 	padding-top: 130px;
	// 	text-align: center;
	// 	font-weight: 500;
	// 	font-size: 15px;
	// 	color: #232323;
	// 	text-decoration: none;
	// 	letter-spacing: -.05em;
	// 	position: relative;

	// 	&.is-type1 {
	// 		background: #f5f5f5 url('@@img/login/auth-type1.png') no-repeat 50% 25px;
	// 		background-size: 90px 90px;
	// 	}

	// 	&.is-type2 {
	// 		background: #f5f5f5 url('@@img/login/auth-type2.png') no-repeat 50% 25px;
	// 		background-size: 90px 90px;
	// 	}
	// }
}