/* 예약확인 - 로그인 */
.mypage-reservation-login {
	
	.login-box {
		margin: -#{$com-mt} -#{$com-ml} 0;
		padding: 30px $com-ml;
		padding: 30px safe(left,$com-ml);
		background: #f5f5f5;

		.form-row:not(:last-child) {
			padding-bottom: 5px;
		}

		input.txt {
			height: 40px;
			line-height: 40px;
		}
	}

	.top-msg {
		margin-bottom: 15px;
		line-height: 1.3;
		text-align: center;
		font-size: 14px;
		color: #232323;
	}

	.btn-area {
		margin-top: 20px;
	}

	.btn-submit {
		box-sizing: border-box;
		display: block;
		width: 100%;
		height: 40px;
		line-height: 40px;
		font-weight: 500;
		font-size: 15px;
		color: #fff;
		background: #232323;
		border: 0;
	}

	.other-action {
		margin-top: 30px;
		text-align: center;

		.btn {
			margin-top: 7px;
		}
	}
}