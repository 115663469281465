.mypage-family-apply {

	.top-guide {
		margin-left: -$com-ml;
		margin-right: -$com-mr;
		margin-bottom: 20px;
		padding-bottom: 20px;
		line-height: 1.3;
		text-align: center;
		font-weight: 300;
		font-size: 16px;
		border-bottom: 1px solid #ddd;
	}

	.agree-wrap {
		margin-bottom: 15px;

		.tit {
			padding: 0 5px;
			font-weight: 300;
		}
	}

}