/* 회원가입 인덱스 - 회원유형 선택 */
.join-index {

	.top-msg {
		margin-bottom: 15px;
		text-align: center;
		font-style: 17px;
		color: #232323;
	}

	.select-type {
		max-width: 335px;
		margin: 0 auto;
	}

	.select-block {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 160px;
		padding-left: 40%;
		position: relative;

		&.is-type1 {    
			background: #f5f5f5 url('@@img/join/member-type1.png') no-repeat 11% 50%;
			background-size: 80px 80px;
		}

		&.is-type2 {
			margin-top: 10px;
			background: #f5f5f5 url('@@img/join/member-type2.png') no-repeat 11% 50%;
			background-size: 80px 80px;
		}
	}

	.type-title {
		font-weight: 500;
		font-size: 19px;
		color: #232323;
		letter-spacing: -.075em;
	}

	.type-desc {
		margin-top: 4px;
		line-height: 15px;
		font-size: 11px;
		color: #666;

		li:before {
			content: '';
			display: inline-block;
			width: 2px;
			height: 2px;
			margin-right: 4px;
			vertical-align: middle;
			background: #b4b4b4;
			position: relative;
			top: -2px;
		}
	}

	.btn-area {
		margin-top: 10px;
	}

	.btn-primary {
		box-sizing: border-box;
		display: inline-block;
		height: 40px;
		line-height: 38px;
		padding: 0 27px;
		text-align: center;
		font-weight: 500;
		font-size: 12px;
		color: #232323;
		text-decoration: none;
		background: transparent;
		border: 1px solid #232323;
		cursor: pointer;
	}
}