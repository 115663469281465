/* 유민미술관 */
.architecture-yuminart {
	.index-info {
		padding: 20px 0;

		.title {
			margin-bottom: 7px;
			font-size: 23px;
			color: #232323;
			span { font-size: 14px; }
		}
	}

	.index-gate {
		a {
			display: block;
			width: 100%;
			padding-top: 44%;
			text-decoration: none;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 50%;
				height: 100%;
				background-repeat: no-repeat;
				background-size: cover;
				position: absolute;
				top: 0;
			}
		}

		.txt {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 50%;
			height: 100%;
			font-weight: 500;
			font-size: 16px;
			color: #fff;
			position: absolute;
			top: 0;

			&:after {
				content: '';
				display: block;
				width: 20px;
				height: 11px;
				margin-top: 15px;
				background: url('@@img/architecture/yumin-gate-arw.png') no-repeat;
				background-size: 20px 11px;
			}
		}

		li:nth-child(1) {
			a:before { background-image: url('@@img/architecture/yumin-gate1.jpg'); left: 0; }
			.txt { background-color: #07304c; right: 0; }
		}
		li:nth-child(2) {
			a:before { background-image: url('@@img/architecture/yumin-gate2.jpg'); right: 0; }
			.txt { background-color: #42a0a8; left: 0; }
		}
		li:nth-child(3) {
			a:before { background-image: url('@@img/architecture/yumin-gate3.jpg'); left: 0; }
			.txt { background-color: #b0895c; right: 0; }
		}
		li:nth-child(4) {
			a:before { background-image: url('@@img/architecture/yumin-gate4.jpg'); right: 0; }
			.txt { background-color: #76777b; left: 0; }
		}
	}

	/* 전시 안내 */
	&-exhibit {
		.main-photo {
			margin: -20px -#{$com-ml} 0;
			margin: -20px safe(left,-$com-ml) 0;

			img { width: 100%; }
		}

		.main-info {
			padding: 20px 0 0;

			.title {
				margin-bottom: 7px;
				line-height: 1.3;
				font-size: 23px;
				color: #232323;
				span { font-size: 14px; }
			}

			.img {
				margin: 20px 0;

				img { width: 100%; }
			}
		}
	}

	/* 이용안내 */
	.common-basic-info {
		margin-top: -20px;
		background: #42a0a8;
	}
}