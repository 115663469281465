/* 이용안내 - 날씨안내 */
.guide-weather {
	margin-bottom: -#{$com-mb};

	.frame-wrap {
		margin: 0 -#{$com-ml};
		margin: 0 safe(left,-$com-ml);

		iframe { vertical-align: top; }
	}

	&.frame-content {
		padding: 0 $com-ml;
		padding: 0 safe(left,$com-ml);
	}

	/* 날씨 아이콘 */
	.weather-ico-big { width: 75px; height: 75px; }
	.weather-ico { width: 50px; height: 50px; }
	.weather-ico-small { width: 33px; height: 33px; }
	
	.current-weather {
		border: 1px solid #d9d8d6;

		&-info {
			margin-bottom: 10px;
			text-align: center;
			font-size: 12px;
		}

		.main {
			display: flex;
			padding: 10px 0;
			border-bottom: 1px solid #d9d8d6;
			position: relative;

			.ico {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 50%;
				text-align: center;
			}

			.txt {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 50%;
				text-align: center;
				font-size: 13px;

				&:before {
					content: '';
					display: block;
					width: 1px;
					background: #d9d8d6;
					position: absolute;
					top: 23px;
					bottom: 23px;
					left: 50%;
				}
			}

			.str {
				font-weight: 500;
				font-size: 24px;
				color: #232323;
			}
		}

		.info {
			display: flex;
		}

		.data {
			flex: 1 0 25%;
			display: flex;
			flex-direction: column;

			&:not(:first-child) { border-left: 1px solid #d9d8d6; }

			dt {
				padding: 10px 0 7px;
				text-align: center;
				font-size: 12px;
				color: #232323;
				border-bottom: 1px solid #d9d8d6;

				&:before {
					content: '';
					display: block;
					height: 21px;
					background-repeat: no-repeat;
					background-size: 27px 21px;
					background-position: 50% 0;
				}
			}

			.t1:before { background-image: url('@@img/guide/weather/current-data-1.png'); }
			.t2:before { background-image: url('@@img/guide/weather/current-data-2.png'); }
			.t3:before { background-image: url('@@img/guide/weather/current-data-3.png'); }
			.t4:before { background-image: url('@@img/guide/weather/current-data-4.png'); }

			dd {
				height: 42px;
				line-height: 42px;
				text-align: center;
				font-weight: 500;
				font-size: 16px;
				color: #232323;
			}
		}
	}

	.forecast {
		margin: 30px -#{$com-ml} -#{$com-mb};
		margin: 30px safe(left,-$com-ml) -#{$com-mb};
		padding: 30px $com-ml;
		padding: 30px safe(left,$com-ml);
		background: #f0f1f3;

		.main-title {
			text-align: center;
			font-weight: 500;
			font-size: 16px;
			color: #232323;
		}

		.time-info {
			text-align: center;
			font-size: 12px;
		}

		.title {
			margin-top: 15px;
			padding-top: 20px;
			padding-bottom: 10px;
			text-align: center;
			font-weight: 500;
			font-size: 14px;
			color: #232323;
			background: url('@@img/com/bg-slash.png') no-repeat 50% 0;
			background-size: 9px 9px;
		}
	}

	.detail-con {
		display: flex;
		box-sizing: border-box;
		text-align: center;
		background: #fff;
		border: 1px solid #d9d8d6;

		dl {
			display: flex;
			flex-direction: column;
			width: 50%;
			padding: 15px 0;
			line-height: 1.3;

			&:not(:first-child) { border-left: 1px solid #d9d8d6; }
		}

		dt {
			font-size: 13px;
			color: #232323;
		}

		dd {
			font-size: 13px;
		}

		.temperature {
			font-weight: 500;
			font-size: 20px;
			color: #232323;
		}

		.ico-data {
			padding: 10px 0;
		}
	}

	.weather-copyright {
		margin-top: 10px;
		padding: 0 5px;
		font-size: 12px;
	}
}