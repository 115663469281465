/* 콘도회원권, 골프회원권 안내 */
.sellinfo-guide {

	/* 개인정보보호법 */	
	.box-desc {
		margin-top: 20px;
		padding: 30px 25px;
		border: 1px solid #ddd;
		text-align: center;

		.tit {
			color: #232323;
			font-size: 13px;
			font-weight: 500;
		}

		.desc {
			margin: 15px 0;
			font-size: 12px;
		}
	}

	/* 서류양식 다운로드 */
	.form-list {
		ul {
			padding: 20px 22px;
			background: #f5f5f5;
			
			@include clearfix;
			
			li:not(:first-child) { margin-top: 5px; }
		}

		.btn { display: block; }
	}

	.table-inner-list {
		.ref-bu-list { margin-top: 10px; }
	}
}