/* 휘닉스 스토리 공통 */
.section-story {

	.main-photo {
		margin: -#{$com-mt} -#{$com-ml} 0;
		margin: -#{$com-mt} safe(left,-$com-ml) 0;
		padding-top: 120%;
		text-align: center;
		color: #fff;
		background-position: 50% 0;
		background-attachment: fixed;
		background-size: cover;
		position: relative;

		&.type2 { color: #232323; }

		.wrap {
			width: 100%;
			position: absolute;
			top: 13%;
			left: 0;
		}

		.top-flag {
			span {
				display: block;
				font-size: 12px;
				line-height: 34px;
				text-align: center;

				&:before,
				&:after {
					content: '';
					display: block;
					width: 30px;
					height: 1px;
					margin: 0 auto;
					background: rgba(255,255,255,.7);
				}
			}

			&:after {
				content: '';
				display: block;
				width: 1px;
				height: 72px;
				margin: 0 auto 10px;
				background: rgba(255,255,255,.7);
			}
		}

		&.type2 {
			.top-flag {
				span {
					&:before,
					&:after { background: #232323; }
				}
				&:after { background: #232323; }
			}
		}

		.main-copy {
			line-height: 1.3;
			font-size: 22px;
		}

		.sub-copy {
			margin-top: 15px;
			font-size: 13px;
			&.type2 { color: #232323; }
		}
	}

	.intro-wrap {
		margin: 30px 0;

		.head-copy {
			margin: 20px 0 10px;
			line-height: 1.3;
			font-weight: 500;
			font-size: 18px;
			color: #232323;
		}

		.con { margin: 10px 0; }

		.title {
			margin: 30px 0 10px;
			padding: 20px 5px 0;
			line-height: 1.3;
			font-weight: 500;
			font-size: 18px;
			color: #232323;
			border-top: 1px solid #d9d8d6;

			span {
				font-weight: 400;
				font-size: 14px;
				color: #b0895c;
			}
		}
	}

	.visual-img {
		margin: 20px 0;
		padding-top: 56.25%;
		background-size: cover;
	}

	.visual-img-wide {
		margin: 20px -#{$com-ml};
		margin: 20px safe(left,-$com-ml);
		padding-top: 42.85%;
		background-size: cover;
	}
}