.mypage-inquiry {

	.inquiry-list {
		.flag-cate {
			margin-right: 5px;
			font-weight: 500;
			&.type1 { color: #006097; }
			&.type2 { color: #d8671a; }
		}

		.cate-txt {
			font-weight: 500;
			color: #666;
		}

		.state {
			font-weight: 500;
			font-size: 13px;
			color: #232323;
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translate(0,-50%);
		}

		.state2 {
			font-weight: 500;
			font-size: 13px;
			color: #d14e40;
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translate(0,-50%);
		}

		.tit {
			font-weight: 500;
			color: #232323;
		}
	}

	.inquiry-detail {
		padding-bottom: 12px;
		border-bottom: 1px solid #ddd;

		.head-info {
			margin-bottom: 20px;
			border-top: 1px solid #232323;

			.row {
				padding: 9px 13px;
				font-size: 14px;
				border-bottom: 1px solid #ddd;

				&.date {
					font-weight: 300;
					font-size: 13px;
				}
			}

			.cate {
				font-weight: 500;

				&.type1 { color: #006097; }
				&.type2 { color: #d8671a; }
			}
		}

		.subject {
			padding: 0 13px;
			font-weight: 500;
			color: #232323;
		}

		.detail-content {
			padding: 10px 13px;
			font-weight: 300;
			font-size: 14px;
		}

		.reply-wrap {
			margin-top: 10px;
			padding: 13px;
			font-weight: 300;
			background: #f5f5f5;
			border-top: 1px solid #ddd;
		}
	}
}