.mypage-myinfo-leave {
	.layout-form {
		padding: 25px 15px;
		background: #f5f5f5;
	}

	.foot-desc {
		margin-top: 15px;
		text-align: center;
		font-weight: 300;
		color: #232323;
	}

	.reject-msg {
		padding: 30px 0;
		text-align: center;
		background: #f5f5f5;
		font-weight: 500;
		color: #232323;
	}
}