/* 골프 - 소개 */
.golf-intro {

	.map-viewer {
		padding-top: 56.25%;
		background: #333;
		position: relative;

		&.is-active {
			.small { display: none; }
			.full { display: block; }
			.btn-toggle:after { @include sprite-2x($ico-zoom-out); }
		}

		.small {
			img {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		.full {
			display: none;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			overflow: auto;
			-webkit-overflow-scrolling: touch;
		}

		.con {
			display: flex;
			min-width: 700px;
			height: 100%;
			justify-content: center;
			align-items: center;
			position: relative;

			img { width: 100%; }
		}

		.btn-toggle {
			display: block;
			width: 40px;
			height: 0;
			max-height: 40px;
			padding-top: 40px;
			background: rgba(35,35,35,.8);
			position: absolute;
			top: 0;
			right: 0;
			overflow: hidden;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: calc(50% - 7px);
				left: calc(50% - 7px);
				@include sprite-2x($ico-zoom-in);
			}
		}
	}

	.course {
		.title {
			margin: 20px 0 5px;
			padding: 5px 0 0;
			font-weight: 500;
			font-size: 14px;
			color: #232323;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 22px;
				height: 2px;
				background: #01609a;
				position: absolute;
				top: 0;
				left: 0;
			}

			span { color: #01609a; }
		}
	}

}