/* 메인 공통 */
.section-main {
	margin-bottom: -$com-mb;

	.controls {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		width: 100%;
		height: 0;
		text-align: center;
		position: absolute;
		bottom: 15px;
		left: 0;
		z-index: 1;
	}

	.visual-paging {
		display: flex;
		width: auto;
		height: auto;
		position: static;

		.swiper-pagination-bullet {
			display: block;
			width: 7px;
			height: 7px;
			background: #fff;
			border-radius: 4px;
			transition: width .2s;

			&:not(:first-child) { margin-left: 5px; }
			&-active { width: 23px; }
		}
	}

	.btn-auto {
		flex-shrink: 0;
		display: flex;
		bottom: -12px;

		@include afterSpriteIR-2x($main-visual-pause,33px,33px,relative);

		&.is-stop { @include afterSpriteIR-2x($main-visual-play,33px,33px,relative); }
	}

	.paging {
		display: flex;
		width: auto;
		height: auto;
		position: static;

		.swiper-pagination-bullet {
			display: block;
			width: 7px;
			height: 7px;
			background: #ccc;
			border-radius: 4px;

			&:not(:first-child) { margin-left: 8px; }
			&-active { background: #b0895c; }
		}

		&.white .swiper-pagination-bullet { background: #fff; }
	}

	&.site-pyeongchang .paging .swiper-pagination-bullet-active { background: #006097; }
	&.site-jeju .paging .swiper-pagination-bullet-active { background: #d8671a; }
	
	.visual-banner {
		box-sizing: border-box;
		margin-top: -55px;
		padding-top: 120%;
		background: #232323;
		position: relative;
		overflow: hidden;

		.slider-list {
			display: flex;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}

		.item {
			flex-shrink: 0;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding-top: 96px;
			text-align: center;
			width: 100%;
			height: 100%;
			background-size: cover;
			position: relative;
		}

		.link {
			display: flex;
			width: 100%;
			height: 100%;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-decoration: none;
		}

		.main-copy {
			line-height: 1.3;
			font-weight: 500;
			font-size: 37px;
			color: #fff;
		}

		.sub-copy {
			margin-top: 7px;
			padding: 0 10px;
			color: #fff;
		}
	}

	.reservation-form {
		padding: 20px $com-ml;
		padding: 20px safe(left,$com-ml);

		label { @include hidden; }

		.row {
			display: flex;
			position: relative;

			&:not(:first-child) {
				margin-top: 9px;
			}
		}

		.select {
			flex: 1;
			font-weight: 400;
			color: #232323;
			& ~ select { margin-left: 10px; }
		}

		.btn-submit {
			flex-shrink: 0;
			display: block;
			width: 32%;
			height: 60px;
			text-align: center;
			font-weight: 500;
			color: #fff;
			background: #b0895c;
		}
	}

	&.site-pyeongchang .reservation-form .btn-submit { background: #006097; }
	&.site-jeju .reservation-form .btn-submit { background: #d8671a; }

	.date-pick-area {
		flex: 1;
		box-sizing: border-box;
		height: 60px;
		background: #fff url('@@img/main/from-to.png') no-repeat 50% 50% / 23px 8px;
		border: 1px solid #ddd;
		border-right: 0;

		.btn-block {
			box-sizing: border-box;
			display: flex;
			height: 100%;
			justify-content: center;
			align-items: center;
			text-decoration: none;
		}

		dl {
			display: flex;
			flex-direction: column;

			&:not(:first-child) { margin-left: 18%; }
		}

		dt {
			@include hidden;
		}

		dd {
			display: flex;
			align-items: center;
			white-space: nowrap;
		}

		.year-month {
			display: flex;
			flex-direction: column;
			line-height: 1.2;
			font-size: 12px;
			color: #999;
			letter-spacing: 0;
			vertical-align: bottom;
		}

		.day {
			margin-left: 10px;
			line-height: 1;
			font-weight: 500;
			font-size: 30px;
			color: #232323;
			letter-spacing: 0;
		}
	}

	.section-title {
		height: 42px;
		line-height: 1.2;
		text-align: center;
		font-weight: 500;
		font-size: 22px;
		color: inherit;
	}

	.link-list {
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;

		li {
			&:not(:first-child) {
				border-top: 1px solid #ddd;
			}
		}

		a {
			display: block;
			height: 54px;
			line-height: 54px;
			padding-left: 20px;
			padding-left: safe(left,20px);
			font-weight: 500;
			font-size: 16px;
			color: #232323;
			text-decoration: none;
			position: relative;

			&:after {
				content: '';
				display: block;
				width: 7px;
				height: 12px;
				background: url('@@img/main/arw-link-list.png') no-repeat 0 0 / 7px 13px;
				position: absolute;
				top: calc(50% - 6px);
				right: 20px;
				right: safe(right,20px);
			}
		}
	}
}