/* 메인 썸네일 목록 */
.main-thumb-list {
	box-sizing: border-box;
	width: 100%;
	padding: 0 $com-ml 30px;
	padding: 0 safe(left,$com-ml) 30px;
	position: relative;
	overflow: hidden;

	.list {
		box-sizing: border-box;
		display: flex;
		width: 100%;
		height: 100%;
	}

	.item {
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		width: 80%;
	}

	.link {
		display: flex;
		flex-direction: column;
		text-decoration: none;
		position: relative;
	}

	.thumb {
		padding-top: 80%;
		position: relative;
		overflow: hidden;
	}

	.img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		vertical-align: top;
	}

	.label {
		display: block;
		width: 104px;
		height: 28px;
		line-height: 28px;
		text-align: center;
		font-weight: 500;
		font-size: 15px;
		color: #fff;
		letter-spacing: 0;
		background: #002d4d;
		position: absolute;
		bottom: 0;
		left: 0;

		.site-pyeongchang & { background: #01609a; }
		.site-jeju & { background: #d8671a; }
	}

	.con {
		padding: 21px 5px;
	}

	.title {
		line-height: 1.2;
		font-weight: 500;
		font-size: 16px;
		color: #232323;

		@include ellipsis;
	}

	.info {
		height: 36px;
		line-height: 18px;
		margin-top: 5px;
		font-weight: 300;
		font-size: 13px;
		color: #666;
		word-wrap: break-word;
		overflow: hidden;
	}
}