/* 회원번호 확인 */
.pop-view-member-num {
	
	.msg {
		text-align: center;
		font-weight: 500;
		
		color: #b08a5d;
	}

	.msg2 {
		margin-top: 10px;
		text-align: center;
		color: #232323;
	}

	.img {
		margin-top: 20px;
		text-align: center;

		img {
			width: 100%;
			max-width: 185px;
		}
	}
}