/* 자주하는 질문 */
.customer-faq {
	.category {
		margin-bottom: 10px;
		padding: 0 10px;

		.depth {
			font-weight: 500;
			font-size: 16px;
			color: #232323;

			&.type1 { color: #006097; }
			&.type2 { color: #d8671a; }
		}

		.gt {
			display: inline-block;
			margin: 0 4px 0 7px;
			vertical-align: middle;
			position: relative;
			top: -2px;
			overflow: hidden;

			@include spriteIR-2x($customer-category-arw);
		}
	}

	.faq-list {
		border-bottom: 1px solid #ddd;

		> dt {
			position: relative;
			font-weight: 300;
			color: #232323;
			border-top: 1px solid #ddd;

			a {
				display: block;
				padding: 14px 30px 14px 40px;
				word-wrap: break-word;
				text-decoration: none;

				&:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					right: 10px;
					margin-top: -4px;

					@include sprite-2x($customer-list-off);
				}
			}

			&:before {
				content: "";
				position: absolute;
				left: 10px;
				top: 50%;
				margin-top: -7px;

				@include sprite-2x($customer-question);
			}

			&.active {
				font-weight: 500;

				a {
					&:after {
						@include sprite-2x($customer-list-on);
					}
				}
			}
		}

		> dd {
			display: none;
			font-size: 14px;
			color: #232323;
			background: #f8f8f8;
			border-top: 1px solid #ddd;
			padding: 14px 14px 14px 40px;
			position: relative;
			word-wrap: break-word;

			&:before {
				content: "";
				display: block;
				position: absolute;
				left: 10px;
				top: 14px;

				@include sprite-2x($customer-answer);
			}
		}
	}
}