/* 나의 예약/구매 리스트 */
.mypage-reservation-list {

	/* 예약/구매상태 구분 */
	.state {
		padding-top: 5px;
		font-weight: 500;
		
		&.type1 { color: $point; }
		&.type2 { color: #d14e40; }
		&.type3 { color: #000; }
		&.type4 { color: #3365a3; }
		&.type5 { color: $sub-color3; }
	}
}