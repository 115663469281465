/* 나의 혜택 조회 */
.mypage-benefit {

	.member-type {
		margin-bottom: 10px;
		text-align: center;
		font-weight: 500;
		font-size: 16px;
		color: #232323;
	}

	.coupon-none {
		text-align: center;
		font-weight: 300;
		font-size: 14px;
	}

	/* 쿠폰 조회 상세 - 바코드 영역 */
	.barcode-wrap {
		margin: -#{$com-mt} -#{$com-ml} 0;
		margin: -$com-mt safe(left,-$com-ml) 0;
		padding: $com-mt $com-ml;
		padding: $com-mt safe(left,$com-ml);
		text-align: center;
		background: #f5f5f5;

		.title {
			margin-bottom: 10px;
			font-weight: 500;
			font-size: 25px;
			color: #232323;
		}

		.barcode {
			max-width: 400px;
			margin: 0 auto;
			padding: 20px 10px;
			background: #fff;
		}

		.img { display: block; width: 100%; }

		.num {
			margin-top: 10px;
			font-size: 14px;
			color: #232323;
		}

		.time-remaining {
			width: 67px;
			height: 22px;
			line-height: 22px;
			margin: 10px auto 0;
			text-align: center;
			font-weight: 500;
			font-size: 15px;
			color: #fff;
			letter-spacing: 0;
			background: #d14e40;
			border-radius: 11px;
		}
	}
}