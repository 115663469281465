//
// 예약/구매 정보 입력 FORM 페이지에서 공통 사용하는 유형
// --------------------------------------------------

/* 시즌권 정보 */
.ticket-title-box {
	padding: 20px 10px 20px;
	text-align: center;
	font-weight: 500;
	font-size: 14px;
	background: #f5f5f5;

	.cate {
		color: #b0895c;
	}

	.title {
		line-height: 1.3;
		color: #232323;
	}
}

/* 약관 동의 영역 */
.agree-wrap {
	margin-top: 15px;

	.tit {
		padding: 0 5px;
		font-weight: 300;
	}
}

/* 약관 동의 영역2 */
.agree-wrap2 {
	.agree-input {
		margin-top: -10px;
	}
}

/* 입력 테이블 하단 가이드 목록 */
.foot-guide-list {
	padding: 15px 0 15px;
	font-size: 12px;
	border-bottom: 1px solid #ddd;
}