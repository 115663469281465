/* 나의 이용내역 */
.mypage-history {

	/* 시즌권 정보 */
	.ticket-title {
		margin-bottom: 14px;
		text-align: center;
		font-weight: 500;

		.cate {
			font-size: 13px;
			color: #b0895c;
		}

		.title {
			line-height: 1.3;
			font-size: 16px;
			color: #232323;
		}
	}

	/* 시즌권 구매내역 없음 */
	.buy-history-none {
		padding: 30px 0;
		text-align: center;

		.main {
			line-height: 1.3;
			font-weight: 300;
			font-size: 16px;
			color: #232323;
		}

		.desc {
			line-height: 1.3;
			margin-top: 10px;
			font-weight: 300;
			font-size: 14px;
		}

		.btn-area {
			margin-top: 25px;
		}
	}

	/* 시즌권, 연간이용권 이용내역 */
	.history-list {
		font-weight: 300;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #ddd;

		.item {
			display: flex;
			padding: 10px 0;

			&:not(:first-child) { border-top: 1px solid #ddd; }
		}

		.date {
			width: 25%;
			text-align: center;
		}

		.time {
			width: 25%;
			text-align: center;
		}

		.title {
			flex: 1;
		}

		&.type2 {
			.date, .time {
				width: 50%;
			}
		}
	}

	/* 가족/지정회원 시즌권 관리 */
	.corp-list {
		.btn-list-action {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translate(0,-50%);
		}

		.state {
			font-size: 11px;
			color: #232323;
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translate(0,-50%);
		}
		.standby {
			color: #d14e40;
		}
	}

	/* 골프 회원권 위약내역	없을 때 */
	.golf-violation-none {
		padding: 10px;
		text-align: center;
		font-weight: 300;
		background: #f5f5f5;
	}
}