/* 이용안내 - 휘닉스 제주 소개 */
.guide-intro-pyeongchang {
	margin-bottom: -#{$com-mb};

	.intro-wrap {
		margin: 30px 0;

		.con {
			margin: 10px 0;
			text-align: center;
		}
	}

	.top-intro {
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: 1px solid #ddd;
		text-align: left;
	}

	.head-copy {
		margin-bottom: 20px;
		text-align: center;
		line-height: 1.4;
		font-weight: 500;
		font-size: 16px;
		color: #232323;
	}

	.video-area {
		margin-bottom: 20px;
		img { width: 100%; }
	}
	
}