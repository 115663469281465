/* 체험휴식-레저 */
.experience-leisure {

	.tab-page-scroll + .common-photo-slider { margin-top: -20px; }
	
	.common-basic-info {
		margin-bottom: 30px;
		background: #2e4b65;
	}

	.common-basic-info + .detail-wrap { padding-top: 0; }

	/* 웰니스 숲길 */
	.img {
		margin-top: 10px;
		img { width: 100%; }
	}

	/* 플라잉 짚 */
	.course-tit {
		position: relative;
		padding-left: 7px;
		color: #333;
		font-weight: 500;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 10px;
			width: 2px;
			height: 2px;
			background: #666;
		}
	}
	.pheonix-course {
		margin: 10px 0 20px;
		
		li {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			
			position: relative;
			padding: 7px 20px;
			border: 1px solid #ddd;
			background: #f5f5f5;
			font-size: 13px;
			font-weight: 500;

			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: -16px;
				left: calc(50% - 7px);
				width: 15px;
				height: 10px;
				background: url('@@img/experience/pyeongchang/arrow-bottom2.png');
				background-size: 100%;
			}
			
			&:not(:first-child) { margin-top: 20px; }

			&:first-child {
				color: #fff;
				border-color: #01609a;
				background-color: #01609a;

				&:after { background-image: url('@@img/experience/pyeongchang/arrow-bottom.png'); }
			}
			&:last-child {
				color: #fff;
				border-color: #b0895c;
				background-color: #b0895c;

				&:after { display: none; }
			}
		
		}
	}

}