/* 통합예약 - 공통 */
.section-reservation {

	.msg-area {
		margin-bottom: 16px;
		text-align: center;

		.copy {
			line-height: 1.3;
			font-size: 17px;
			color: #232323;
		}

		.desc {
			margin-top: 5px;
			line-height: 1.3;
			font-weight: 300;
			font-size: 14px;
			color: #666;
		}
	}

	/* 페이지 상단 날짜선택 */
	.top-date-pick {
		margin: -#{$com-mt} -#{$com-ml} 0;
		padding: $com-mt $com-ml;
		padding: safe(top,$com-mt) safe(left,$com-ml);
		background: #f5f5f5;

		.category-select {
			margin-bottom: 10px;
			select { text-align-last: center; font-weight: 500; }
		}

		.date-pick-area {
			background: #fff url('@@img/reservation/from-to2.png') no-repeat 50% 50% / 23px 8px;

			.btn-block {
				display: flex;
				justify-content: center;
				padding: 14px 25px;
				text-decoration: none;
			}

			dl {
				display: flex;
				flex-direction: column;

				&:not(:first-child) { margin-left: 18%; }
			}

			dt {
				padding-bottom: 7px;
				font-weight: 500;
				font-size: 11px;
				color: #b0895c;
			}

			dd {
				margin-top: -19px;
				white-space: nowrap;
			}

			.year-month {
				font-size: 13px;
				letter-spacing: 0;
				vertical-align: bottom;
			}

			.day {
				margin-left: 17px;
				line-height: 1;
				vertical-align: bottom;
				font-weight: 500;
				font-size: 28px;
				color: #232323;
				letter-spacing: 0;
			}
		}
		
		.btn-area {
			margin-top: 10px;
		}

		.btn-search {
			font-size: 16px;

			.search {
				display: inline-block;
				margin-right: 5px;
				vertical-align: middle;
				position: relative;
				top: -1px;

				@include sprite-2x($reservation-search);
			}
		}
	}
}