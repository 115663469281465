/* 평창 메인 */
.main-pyeongchang {
	.quick-link {
		padding: 35px $com-ml 30px;
		padding: 35px safe(left,$com-ml) 30px;
		color: #232323;
		background: #f7f7f7;

		.list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			li {
				display: block;
				width: 25%;

				&:nth-child(4) ~ li {
					margin-top: 23px;
				}
			}

			a {
				display: block;
				text-align: center;
				line-height: 1.3;
				font-size: 13px;
				text-decoration: none;

				&:before {
					content: '';
					display: block;
					width: 63px;
					height: 63px;
					margin: 0 auto 14px;
					background-size: cover;
				}

				&.seq1:before { background-image: url('@@img/main/pyeongchang/quick1.png'); }
				&.seq2:before { background-image: url('@@img/main/pyeongchang/quick2.png'); }
				&.seq3:before { background-image: url('@@img/main/pyeongchang/quick3.png'); }
				&.seq4:before { background-image: url('@@img/main/pyeongchang/quick4.png'); }
				&.seq5:before { background-image: url('@@img/main/pyeongchang/quick5.png'); }
				&.seq6:before { background-image: url('@@img/main/pyeongchang/quick6.png'); }
				&.seq7:before { background-image: url('@@img/main/pyeongchang/quick7.png'); }
				&.seq8:before { background-image: url('@@img/main/pyeongchang/quick8.png'); }
				&.seq9:before { background-image: url('@@img/main/pyeongchang/quick9.png'); }
				&.seq10:before { background-image: url('@@img/main/pyeongchang/quick10.png'); }
				&.seq11:before { background-image: url('@@img/main/pyeongchang/quick11.png'); }
				&.seq12:before { background-image: url('@@img/main/pyeongchang/quick12.png'); }
				&.seq13:before { background-image: url('@@img/main/pyeongchang/quick13.png'); }
				&.seq14:before { background-image: url('@@img/main/pyeongchang/quick14.png'); }
				&.seq15:before { background-image: url('@@img/main/pyeongchang/quick15.png'); }
			}

			span {
				font-weight: 500;
				color: #232323;
			}
		}
	}

	.ski-board {
		padding: 35px 0 25px;
		color: #fff;
		background: #333;
	}
	
	.promotion {
		padding: 35px 0 20px;
		color: #232323;
	}

	.waterpark {
		padding: 35px $com-ml 45px;
		padding: 35px safe(left,$com-ml) 45px;
		color: #fff;
		background: #005890;

		.link {
			display: block;
			text-decoration: none;
		}

		.bg {
			padding-top: 72.4%;
			background: url('@@img/main/pyeongchang/waterpark.jpg') no-repeat 0 0 / cover;
		}

		.con {
			text-align: center;
		}

		.copy {
			margin-top: 25px;
			line-height: 1.4;
			font-weight: 500;
			font-size: 20px;
		}

		.desc {
			margin-top: 12px;
			font-weight: 300;
			font-size: 14px;
		}

		.action {
			margin-top: 15px;
		}

		.btn-link {
			box-sizing: border-box;
			display: inline-block;
			width: 36%;
			height: 35px;
			line-height: 33px;
			vertical-align: middle;
			text-align: center;
			font-size: 14px;
			color: #fff;
			text-decoration: none;
			letter-spacing: 0;
			border: 1px solid #fff;
			border-radius: 18px;

			& + .btn-link {
				margin-left: 5px;
			}
		}
	}
}