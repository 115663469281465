/* 쿠폰 예약 */
.reservation-coupon-list {
	.top-desc {
		margin-bottom: 10px;
		text-align: center;
		color: #232323;
	}

	.coupon-number-form {
		border-top: 1px solid #232323;
		border-bottom: 1px solid #232323;

		.head {
			display: block;
			height: 40px;
			line-height: 40px;
			padding: 0 10px;
			font-weight: 500;
			font-size: 14px;
			color: #000;
			background: #f5f5f5;
		}

		.wrap {
			display: block;
			padding: 10px;
		}

		.result {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 5px;

			.state-msg { margin-top: 0; }
			.btn { flex-shrink: 0; width: 70px; }
		}
	}

	.coupon-list {
		margin-top: 20px;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #232323;

		.head {
			display: block;
			height: 40px;
			line-height: 40px;
			padding: 0 10px;
			font-weight: 500;
			font-size: 14px;
			color: #000;
			background: #f5f5f5;
		}

		.no-item {
			display: block;
			padding: 50px 0;
			text-align: center;
		}

		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px;
			border-top: 1px solid #ddd;
		}

		.info {
			flex: 1;
			padding-right: 10px;
		}

		.title {
			color: #232323;
		}

		.date {
			line-height: 1.3;
			font-size: 12px;
		}

		.action {
			flex-shrink: 0;
		}

		.btn-list-action {
			width: 70px;
		}
	}
}