/* 객실 상세 - 호텔 */
.room-detail-hotel {
	margin: -#{$com-mt} 0 -#{$com-mb};

	/* 체크인아웃 시간 */
	.time-con {
		display: flex;
		justify-content: center;
		margin-bottom: 15px;
		padding: 0 0 15px;
		border-bottom: 1px solid #d9d8d6;

		dl {
			text-align: center;
			position: relative;

			&:first-child:after {
				content: '~';
				width: calc(200% + 56px);
				text-align: center;
				font-weight: 500;
				font-size: 23px;
				position: absolute;
				top: 3px;
				left: 0;
			}
		}

		dl + dl { margin-left: 56px; }

		dt {
			box-sizing: border-box;
			display: block;
			line-height: 1.4;
			font-weight: 400;
			font-size: 11px;
			color: #b0895c;
		}

		dd {
			display: block;
			font-weight: 500;
			font-size: 20px;
			letter-spacing: 0;
		}
	}

	.section-title {
		margin-bottom: 15px;
		text-align: center;
		font-weight: 500;
		font-size: 16px;
	}

	/* 접힌 내용 */
	.folding-content { display: none; }

	/* 상세정보 */
	.detail-info {
		margin: 35px 15px 55px;
		margin: 35px safe(left,15px) 55px;
		padding: 0 0 40px;
		border-bottom: 1px solid #d9d8d6;
		position: relative;

		.section-title { color: #232323; }
	}

	.content-area {
		text-align: center;
		font-size: 13px;

		.foot-desc {
			margin-top: 15px;
			text-align: center;
			font-size: 12px;
		}

		.head-sub2 {
			text-align: center;
			padding-top: 20px;
			padding-left: 0;
			background: url('@@img/com/bg-slash.png') no-repeat 50% 0;
			background-size: 9px 9px;

			&:not(:first-child) { margin-top: 20px; }
		}

		.folding-content {
			margin-top: 20px;
		}
	}

	/* 하단 정보 */
	.foot-info {
		margin-top: 35px;
		padding: 20px 15px 55px;
		padding: 20px safe(left,15px) 55px;
		background: #76777b;

		.section-title { color: #fff; }

		.content-area {
			padding-bottom: 40px;
			color: #d9d8d6;
			border-bottom: 1px solid #999;
			position: relative;
		}

		.head-sub2 {
			color: #f7f7f7;
			background-image: url('@@img/com/bg-slash2.png');
		}

		.folding-content {
			margin-top: 30px;
			margin-bottom: -40px;
			padding-top: 20px;
			padding-bottom: 40px;
			border: 1px solid #999;
			border-bottom: 0;
		}

		.btn-expand-toggle {
			@include spriteIR-2x($ico-expand2);
			&.is-active { @include spriteIR-2x($ico-fold2); }
		}
	}

	.amenity {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 10px;

		li {
			flex-shrink: 0;
			width: 28%;
			text-align: center;
			font-size: 12px;
			color: #fff;

			&:before {
				content: '';
				display: block;
				width: 45px;
				height: 45px;
				margin: 0 auto 5px;
				background-size: 45px 45px;
			}

			&:nth-child(3) ~ li { margin-top: 25px; }
		}

		.ico1:before { background-image: url('@@img/room/amenity1.png'); }
		.ico2:before { background-image: url('@@img/room/amenity2.png'); }
		.ico3:before { background-image: url('@@img/room/amenity3.png'); }
		.ico4:before { background-image: url('@@img/room/amenity4.png'); }
		.ico5:before { background-image: url('@@img/room/amenity5.png'); }
		.ico6:before { background-image: url('@@img/room/amenity6.png'); }
		.ico7:before { background-image: url('@@img/room/amenity7.png'); }
	}

	.item-list {
		display: flex;
		flex-wrap: wrap;
		padding: 0 8%;
		letter-spacing: -.025em;

		li {
			box-sizing: border-box;
			width: 50%;
			padding-left: 10px;
			padding-right: 10px;
			text-align: left;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 2px;
				height: 2px;
				background: #d9d8d6;
				position: absolute;
				top: .7em;
				left: 0;
			}

			&:nth-child(2) ~ li { margin-top: 3px; }
		}

		strong { color: #fff; }
	}
}