/* 예약상품 정보 */
.reservation-product {
	.product-visual {
		margin: -#{$com-mt} -#{$com-ml} 0;
		padding-top: 42.86%;
		background-size: cover;
	}

	.common-basic-info {
		background: #4c6782;

		.sub-info {
			margin: -9px 0 9px;
			text-align: center;
			font-size: 14px;
		}

		.etc-ticket-info {
			text-align: center;
		}
	}

	.golf-index-form {
		.row {
			&:not(:first-child) { margin-top: 6px; }
		}

		.btn-date-input {
			display: block;

			.input-box {
				height: 40px;
				line-height: 40px;
				padding-left: 7px;
				font-size: 13px;
				color: #4d4d4d;
				letter-spacing: 0;
			}
		}

		.select {
			height: 40px;
			line-height: 40px;
		}
	}
}