/* 마이페이지 공통 */
.section-mypage {

	.msg-area {
		margin-bottom: 16px;
		text-align: center;

		.copy {
			line-height: 1.3;
			font-size: 17px;
			color: #232323;
		}

		.desc {
			margin-top: 5px;
			line-height: 1.3;
			font-weight: 300;
			font-size: 14px;
			color: #666;
		}
	}
	
}