/* pages 공통 */
.str { color: #232323; } // 진하게
.point { color: $point; } // 포인트컬러1: 금색
.point2 { color: $point2; } // 포인트컬러2: 파란색
.point3 { color: $point3; } // 포인트컬러3: 주황색

/* 본문 풀배너 */
.full-banner {
	margin-top: 30px;

	img { width: 100%; vertical-align: top; }
}