/* 어트랙션 운영 현황 */
.waterpark-intro-state {

	.attraction-state {
		margin-bottom: 15px;
		text-align: right;

		span {
			display: inline-block;
			height: 16px;
			padding-left: 21px;
			font-size: 14px;
			line-height: 16px;
		
			&.ing {
				background: url('@@img/waterpark/operation-ing.png') no-repeat left top;
				background-size: 16px 16px;
			}
			&.none {
				margin-left: 15px;
				background: url('@@img/waterpark/operation-none.png') no-repeat left top;
				background-size: 16px 16px;
			}
		}
	}
	
	.attraction-wrap {
		table {
			width: 100%;
			table-layout: fixed;
			background: #fff;

			&:not(:first-child) { margin-top: 30px; }

		}

		th,	td {
			box-sizing: border-box;
			text-align: center;
			vertical-align: middle;
			white-space: nowrap;
		}

		th {
			height: 35px;
			background: #014f7e;
			border: 1px solid #014f7e;
			color: #fff;
			font-size: 16px;
			font-weight: 500;
			letter-spacing: -.05em;
			
			&.outdoor { 
				background: #1989cb;
				border: 1px solid #1989cb;

				span { font-size: 14px; }
			}
		}
			
		td {
			font-size: 13px;
			font-weight: 500;
			vertical-align: top;
			border: 1px solid #ddd;
			
			div { 
				display: flex;
				align-items: center;
				justify-content: center;

				&:first-child {
					height: 30px;
					background: #f5f5f5;
				}
				&:last-child {
					height: 50px;
					img { height: 21px; }
				}
			}
		}
	}

}