/* 메인 썸네일 목록3 */
.main-thumb-list3 {
	box-sizing: border-box;
	width: 80%;
	margin: 0 auto;
	padding: 0 0 45px;
	position: relative;

	.list {
		box-sizing: border-box;
		display: flex;
		width: 100%;
		height: 100%;
	}

	.item {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		width: 100%;
		padding: 0 7px;
	}

	.link {
		display: flex;
		flex-direction: column;
		text-decoration: none;
		position: relative;
	}

	.thumb {
		padding-top: 125%;
		position: relative;
		overflow: hidden;
	}

	.img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		vertical-align: top;
	}

	.label {
		display: block;
		width: 104px;
		height: 28px;
		line-height: 28px;
		text-align: center;
		font-weight: 500;
		font-size: 15px;
		color: #fff;
		letter-spacing: 0;
		background: #002d4d;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.con {
		box-sizing: border-box;
		display: block;
		width: 100%;
		padding: 15px;
		background: rgba(35,35,35,.7);
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.title {
		line-height: 1.2;
		font-weight: 500;
		font-size: 18px;
		color: #fff;
		letter-spacing: -.03em;

		@include ellipsis;
	}

	.info {
		height: 40px;
		line-height: 20px;
		margin-top: 10px;
		font-size: 14px;
		color: #c0c0c0;
		letter-spacing: -.05em;
		word-wrap: break-word;
		overflow: hidden;
	}
}