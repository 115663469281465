/* 휘닉스 상품권 */
.story-gift-card {
	margin-top: -$com-mt;

	.gift-card-intro {
		padding: 40px 0;
		text-align: center;

		&:before {
			content: '';
			display: block;
			padding-top: 43%;
			background: url('@@img/story/gift-card-intro.png') no-repeat;
			background-size: cover;
		}

		.title {
			margin: 30px 0 15px;
			color: #232323;
			font-size: 18px;
			font-weight: 500;
		}
	}

	.gift-card-list {
		li {
			display: flex;
			align-items: center;
			&:not(:first-child) { margin-top: 10px; }
		}
		
		span:nth-child(1) {
			flex: 1;
			img { width: 100%; }
		}
		span:nth-child(2) {
			width: 120px;
			padding-left: 20px;
			color: #333;
			font-size: 14px;
			font-weight: 500;
		}
	}
}