/* 이용약관 */
.license-content {
	margin-top: -#{$com-mt};
	margin-bottom: -#{$com-mb};

	.content-select {
		margin: 20px 0;
		.select { border-color: #232323; }
	}

	.top-desc {
		margin: 20px 0;
	}
}