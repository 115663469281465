/* 휘닉스 앱 */
.story-app {
	margin-top: -$com-mt;
	
	.app-intro { padding: 40px 0; }

	.main-copy {
		color: #232323;
		font-size: 18px;
		font-weight: 500;
	}

	.desc { margin-top: 20px; }

	.app-list {
		.view {
			height: 300px;
			background: #f5f5f5;
			
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.btn-area { 
			margin: 20px 0;
			text-align: center;

			img { height: 60px; }
		}
	}

}