/* 웨딩 */
.party-wedding {
	margin: -#{$com-mt} 0 -#{$com-mb};

	.side-photo {
		.title { 
			font-size: 18px;
			line-height: 1.3;
		}

		.con {
			margin-bottom: 35px;
			font-weight: 500;
			text-align: center;
		}

		.info-wrap {
			margin: 0 0 35px;

			.dot-bu-list { 
				margin-top: 0;
				color: #666;
			}
		}
	}

	.info-wrap {
		margin-top: 35px;
		text-align: center;
		
		.title {
			color: #232323;
			font-size: 16px;
			font-weight: 500;
			text-align: center;
			letter-spacing: -.05em;
		}

		.dot-bu-list {
			margin-top: 15px;
		
			li {
				color: #666;
				font-size: 13px;
				text-align: left;
			}
		}

		.btn-area {
			margin-top: 20px;
			text-align: center;

			.btn { width: 250px; }
		}
	}

	/* 휘닉스 제주 웨딩 특전 */
	.wedding-benefit {
		padding: 30px 8.5% $com-mb;
		padding: 30px safe(left,8.5%) $com-mb;
		
		text-align: center;

		.title {
			color: #fff;
			font-size: 16px;
			font-weight: 500;
			text-align: center;

			&.add-benefit {
				margin-top: 15px;
				padding-top: 20px;
				background: url('@@img/com/bg-slash2.png') no-repeat 50% 0;
				background-size: 9px 9px;
			}
		}

		.con {
			margin-top: 15px;
			color: #fff;
			font-size: 13px;
			text-align: center;
		}

		.dot-bu-list {
			li {
				color: #fff;
				font-size: 13px;
				text-align: left;
				
				&::before { background: #fff; }
			}
		}

		.img {
			margin-top: 20px;
			padding-top: 40%;
			background: url('@@img/party/jeju/wedding-benefit.jpg');
			background-size: cover;
		}
	}

	.site-pyeongchang & .wedding-benefit {
		background: #2f4a65;
	}
	.site-jeju & .wedding-benefit {
		background: #42a0a8;
	}
}