/* 건축가 소개 */
.architect-intro {
	margin-top: 40px;

	.common-content & {
		margin-left: -#{$com-ml};
		margin-left: safe(left,-$com-ml);
		margin-right: -#{$com-mr};
		margin-right: safe(right,-$com-mr);
	}

	.top {
		height: 135px;
		margin: 0 $com-ml;
		margin: 0 safe(left,$com-ml);
		padding-top: 11px;
		padding-left: 145px;
		position: relative;
	}

	.photo {
		position: absolute;
		top: 0;
		left: 0;

		img { width: 125px; height: 125px; }
	}

	.title {
		padding-bottom: 25px;
		font-weight: 500;
		font-size: 19px;
		color: #232323;
		position: relative;

		&:after {
			content: '';
			display: block;
			width: 25px;
			height: 1px;
			background: #999;
			position: absolute;
			bottom: 10px;
			left: 0;
		}
	}

	.name {
		line-height: 1.2;
		font-weight: 500;
		font-size: 16px;
		color: #232323;

		span {
			font-size: 12px;
			color: #b8b8b8;
			white-space: nowrap;
		}
	}

	.con {
		margin-top: -50px;
		padding: 50px $com-ml $com-mb;
		padding: 50px safe(left,$com-ml) $com-mb;
		font-size: 13px;
		color: #fff;
		background: #76777b;

		p:not(:first-child) { margin-top: 20px; }
		.up { font-size: 16px; }
	}

	.btn-area {
		margin-top: 20px;
		text-align: center;

		.link {
			display: inline-block;
			height: 40px;
			line-height: 38px;
			padding: 0 30px;
			font-size: 14px;
			color: #fff;
			text-decoration: none;
			border: 1px solid #fff;
		}
	}
}