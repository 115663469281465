/* 워터파크 이용안내 */
.waterpark-guide {
	margin-bottom: -#{$com-mb};

	.common-basic-info {
		background: #268dc8;
	}

	/* 입장 및 퇴장 안내 */
	.in-out-guide {
		> li {
			display: flex;
			padding: 15px;
			border: 1px solid #ddd;
			position: relative;

			&:not(:first-child) {
				margin-top: 38px;

				&:after {
					content: '';
					display: block;
					position: absolute;
					top: -25px;
					left: calc(50% - 9px);

					@include sprite-2x($waterpark-step-list-arw);
				}
			}
		}

		.step {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			width: 36px;
			height: 36px;
			margin-right: 10px;
			text-align: center;
			line-height: 11px;
			font-weight: 500;
			font-size: 8px;
			color: #fff;
			letter-spacing: 0;
			background: #232323;
			border-radius: 18px;
		}

		.con {
			flex: 1;
		}

		.tit {
			padding: 0 5px;
			font-weight: 500;
			color: #232323;
		}
	}
}