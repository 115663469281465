/* 휴면계정 로그인 안내 */
.login-dormant {
	
	.result-box {
		padding: 15px 0 13px;
		text-align: center;
		font-weight: 300;
		background: #f5f5f5;
	}

}