/* 제주 메인 */
.main-jeju {

	.quick-link {
		padding: 0 $com-ml;
		padding: 0 safe(left,$com-ml);
		color: #232323;
		background: #f5f3ee;

		li {
			&:not(:first-child) {
				border-top: 1px solid #ddd;
			}
		}

		a {
			box-sizing: border-box;
			display: flex;
			align-items: center;
			padding: 25px 0;
			text-decoration: none;

			&:before {
				content: '';
				flex-shrink: 0;
				display: flex;
				width: 28%;
				height: 50px;
			}

			&.seq1:before { background: url('@@img/main/jeju/quick1.png') no-repeat 50% 50% / 50px 50px; }
			&.seq2:before { background: url('@@img/main/jeju/quick2.png') no-repeat 50% 50% / 50px 50px; }
			&.seq3:before { background: url('@@img/main/jeju/quick3.png') no-repeat 50% 50% / 50px 50px; }
		}

		.con-wrap {
			flex: 1;
		}

		.title {
			line-height: 1.3;
			font-weight: 500;
			font-size: 16px;
			color: #232323;
		}

		.con {
			margin-top: 5px;
			line-height: 19px;
			font-size: 13px;
			color: #666;
		}
	}
	
	.food-info {
		padding: 35px 0 20px;
		color: #232323;
		overflow: hidden;
	}
	
	.promotion {
		padding: 35px 0 20px;
		color: #232323;
		background: #f7f7f7;
	}

	.wedding {
		padding: 35px 0 25px;
		color: #fff;
		background: #333;
	}

	.yuminart {
		padding: 35px $com-ml 45px;
		padding: 35px safe(left,$com-ml) 45px;
		color: #fff;
		background: #42a0a8;

		.link {
			display: block;
			text-decoration: none;
		}

		.bg {
			padding-top: 72.4%;
			background: url('@@img/main/jeju/yuminart.jpg') no-repeat 0 0 / cover;
		}

		.con {
			text-align: center;
		}

		.copy {
			margin-top: 25px;
			line-height: 1.4;
			font-weight: 500;
			font-size: 20px;
		}

		.desc {
			margin-top: 12px;
			font-weight: 300;
			font-size: 14px;
		}

		.action {
			margin-top: 15px;
		}

		.btn-link {
			box-sizing: border-box;
			display: inline-block;
			width: 36%;
			height: 35px;
			line-height: 33px;
			vertical-align: middle;
			text-align: center;
			font-size: 14px;
			color: #fff;
			text-decoration: none;
			letter-spacing: 0;
			border: 1px solid #fff;
			border-radius: 18px;

			& + .btn-link {
				margin-left: 5px;
			}
		}
	}
}