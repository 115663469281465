/* 객실 상세 - 콘도/유스호스텔 */
.room-detail-condo {
	margin: -#{$com-mt} 0 -#{$com-mb};

	/* 기본 정보 */
	.basic-info {
		padding: 36px 10% 43px;
		color: #fff;
		background: #2e4b65;

		@at-root {
			.site-jeju & { background: #42a0a9; }
		}

		.title {
			margin-bottom: 15px;
			padding-bottom: 9px;
			text-align: center;
			font-weight: 500;
			font-size: 18px;
			border-bottom: 1px solid rgba(217,216,214,.2);
		}

		.room-info-wrap {
			text-align: center;
		}

		.data-layout-table {
			th:before {
				background: #fff;
			}
		}
	}

	/* 체크인아웃 시간 */
	.time-con {
		display: flex;
		justify-content: center;
		margin-top: -15px;
		margin-bottom: 15px;
		padding: 15px 0;
		border-bottom: 1px solid rgba(217,216,214,.2);

		dl {
			text-align: center;
			position: relative;

			&:first-child:after {
				content: '~';
				width: calc(200% + 56px);
				text-align: center;
				font-weight: 500;
				font-size: 23px;
				color: #fff;
				position: absolute;
				top: 3px;
				left: 0;
			}
		}

		dl + dl { margin-left: 56px; }

		dt {
			box-sizing: border-box;
			display: block;
			line-height: 1.4;
			font-weight: 400;
			font-size: 11px;
			color: rgba(255,255,255,.6);
		}

		dd {
			display: block;
			font-weight: 500;
			font-size: 20px;
			color: #fff;
			letter-spacing: 0;
		}
	}

	.list-ico-info {
		color: #232323;

		li {
			display: flex;
			justify-content: flex-start;
			padding-right: 3px;

			&:not(:first-child) {
				margin-top: 8px;
			}
		}

		.txt { flex: 1; margin: 0; }

		.ico-action {
			display: block;
			margin-left: 5px;

			&.plane { @include afterSpriteIR-2x($ico-plane,20px,20px,relative); }
			&.vr { @include afterSpriteIR-2x($ico-vr2,20px,20px,relative); }
		}
	}

}