/* 워터파크 티켓 */
.waterpark-ticket {
	.ticket-area {
		margin: 0 -#{$com-ml};
		margin: 0 safe(left,-$com-ml);

		img { width: 100%; }
	}
	.tab-page-scroll + .ticket-area { margin-top: -20px; }

	/* 이용방법 */
	.use-guide-process {
		padding: 25px 0;
		background: #f5f5f5;

		li {
			display: flex;
			flex-direction: column;
			position: relative;
			padding: 0 20px 0 110px;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 75px;
				left: 44px;

				@include sprite-2x($waterpark-step-list-arw2);
			}

			&:not(:first-child) { margin-top: 35px; }
			&:last-child:after { background: none; }
		}

		.step {
			color: #006097;
			font-size: 13px;
			font-weight: 500;

			&:before {
				content: '';
				display: inline-block;
				position: absolute;
				left: 25px;
				top: 0;
			}
		}

		.tit {
			color: #232323;
			font-size: 13px;
			font-weight: 500;
		}

		.desc { font-size: 13px; }

		&.season li:nth-child(1) .step:before { @include sprite-2x($waterpark-guide-season1); }
		&.season li:nth-child(2) .step:before { @include sprite-2x($waterpark-guide-season2); }
		&.season li:nth-child(3) .step:before { @include sprite-2x($waterpark-guide-season3); }
		&.season li:nth-child(4) .step:before { @include sprite-2x($waterpark-guide-season4); }
		&.room li:nth-child(1) .step:before { @include sprite-2x($waterpark-guide-room1); }
		&.room li:nth-child(2) .step:before { @include sprite-2x($waterpark-guide-room2); }
		&.room li:nth-child(3) .step:before { @include sprite-2x($waterpark-guide-room3); }
		&.room li:nth-child(4) .step:before { @include sprite-2x($waterpark-guide-room4); }
	}

	/* 사진등록 */
	.photo-sample-wrap {
		padding: 25px 0;
		background: #f5f5f5;

		.photo-sample:not(:first-child) { margin-top: 25px; }
	}

	.photo-sample {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 20px;

		.tit {
			display: inline-block;
			margin-bottom: 18px;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: -.05em;

			&:before {
				content: '';
				display: inline-block;
				margin-right: 5px;
				vertical-align: -5px;
			}

			&.case-good { 
				color: #006097;
				&:before { @include sprite-2x($waterpark-good); }
			}
			&.case-bad { 
				color: #d14e40;
				&:before { @include sprite-2x($waterpark-bad); }
			}
		}
		
		.desc {
			margin-top: 12px;
			font-size: 12px;
			text-align: center;
			line-height: 1.3;

			strong { font-size: 13px; }
		}

		ul {
			display: flex;
			li:first-child { margin-right: 20px; }
		}

		img { width: 80px; }
	}
	
}