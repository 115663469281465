/* 연혁 */
.company-history {

	.history-title {
		padding-top: 40px;
		color: #232323;
		font-size: 18px;
		font-weight: 500;
		text-align: center;
		letter-spacing: -.05em;
		line-height: 1;

		background-repeat: no-repeat;
		background-position: center top;

		&.history1 { 
			background-image: url('@@img/company/history1.png');
			background-size: 34px 30px;
		}
		&.history2 { 
			background-image: url('@@img/company/history2.png');
			background-size: 36px 28px;
		}
		&.history3 { 
			background-image: url('@@img/company/history3.png');
			background-size: 45px 28px;
		}
	}

	.history-list {
		position: relative;
		margin-top: 26px;

		li {
			display: flex;
			&:not(:first-child) { margin-top: 20px; }
		}

		.year {
			width: 55px;
			color: #232323;
			font-size: 13px;
			font-weight: 500;
			text-align: center;
		}

		.con {
			width: calc(100% - 55px);
		
			.month {
				width: 45px;
				font-size: 13px;
				text-align: center;
			}

			.desc {
				box-sizing: border-box;
				width: calc(100% - 45px);
				padding-left: 15px;
				font-size: 13px;
			}
		}

		&:before {
			content: '';
			display: block;
			width: 1px;
			height: 100%;
			background: #d9d8d6;
			position: absolute;
			top: 0;
			left: 55px;
		}
	
		&:after {
			content: '';
			display: block;
			width: 1px;
			height: 100%;
			background: #d9d8d6;
			position: absolute;
			top: 0;
			left: 100px;
		}
	}

}