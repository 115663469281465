/* 객실 상세 - 별장 */
.room-detail-villa {
	margin: -#{$com-mt} 0 -#{$com-mb};
	
	.info-desc {
		padding: 20px $com-ml;
		padding: 20px safe(left,$com-ml);
		font-size: 13px;

		p:not(:first-child) { margin-top: 10px; }

		.main {
			line-height: 1.4;
			font-size: 18px;
			color: #232323;
		}
	}

	/* 힐리우스 이용안내 */
	.hillius-info {
		padding: 25px 0;
		text-align: center;
		background: #eaebed;

		dl {
			&:not(:first-child) {
				padding-top: 35px;
				background: url('@@img/com/bg-slash.png') no-repeat 50% 15px;
				background-size: 9px 9px;
			}

			&:before {
				content: '';
				display: block;
				width: 50px;
				height: 40px;
				margin: 0 auto;
			}

			&:nth-child(1):before { background: url('@@img/room/jeju/hillius1.png') no-repeat 0 0 / 50px 40px; }
			&:nth-child(2):before { background: url('@@img/room/jeju/hillius2.png') no-repeat 0 0 / 50px 40px; }
			&:nth-child(3):before { background: url('@@img/room/jeju/hillius3.png') no-repeat 0 0 / 50px 40px; }
		}

		dt {
			display: block;
			font-size: 12px;

			strong {
				font-size: 17px;
				color: #232323;
			}
		}

		dd {
			display: block;
			margin-top: 5px;
			font-size: 13px;
		}
	}
	
}