/* 객실 */
.reservation-room {

	/* 비회원일때 로그인 혜택 안내 메시지 */
	.nonmember-msg {
		margin: 0 -#{$com-ml};
		margin: 0 safe(left,-$com-ml);
		padding: 16px 0;
		text-align: center;
		font-weight: 500;
		font-size: 12px;
		color: #232323;
		background: #fff;
		border-bottom: 1px solid #ddd;
		position: relative;
		z-index: 1;

		@supports (position: sticky) {
			position: sticky;
			top: 0;
			left: 0;
		}
	}

	/* 추첨예약안내 */
	.raffle-guide {
		text-align: center;

		img { max-width: 100%; }
	}

	/* 추첨예약일정/기준 */
	.raffle-schedule-rule {
		.common-basic-info2 {
			margin-left: 0;
			margin-right: 0;

			&-title { font-size: 16px; }
		}
	}

	/* 추첨 회원 번호 영역 */
	.raffle-member-number {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 15px;
		padding: 15px;
		text-align: center;
		font-size: 13px;
		background: #f5f5f5;

		.head {
			flex-shrink: 0;
			margin-right: 10px;
			vertical-align: middle;
			font-weight: 500;
			color: #232323;
		}

		.value {
			display: inline-block;
			vertical-align: middle;

			&:before {
				content: '';
				display: inline-block;
				width: 1px;
				height: 12px;
				margin-right: 10px;
				vertical-align: middle;
				background: #ddd;
				position: relative;
				top: -1px;
			}
		}
	}

	/* 추첨 신청 */
	.raffle-apply {
		.foot-desc {
			margin-top: 22px;
			padding-left: 15px;
			text-indent: -13px;
			font-size: 13px;

			li:not(:first-child) {
				margin-top: 10px;
			}

			.num {
				margin-right: 2px;
				font-weight: 500;
			}

			.sub {
				font-size: 12px;
				color: #c1a37f;
			}
		}
	}

	/* 추첨결과확인 */
	.raffle-result {
		.state-wrap {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 13px;
			color: #666;
		}
		.state {
			display: flex;
			align-items: center;

			strong {
				margin-left: 7px;
				font-weight: 500;
				font-size: 16px;
			}
		}
		.state-ok {
			color: #dc3434;
		}
		.num {
			display: inline-block;
			width: 100px;
			margin-left: 7px;
			color: #232323;
		}
	}

	/* 박수합계 */
	.count-sum {
		display: flex;
		height: 30px;
		line-height: 30px;
		padding: 0 5px;
		text-align: center;
		font-weight: 500;
		font-size: 12px;
		background: #f8f8f8;
		border-bottom: 1px solid #e8e8e8;

		dt {
			flex: 1;
		}

		dd {
			flex: 1;
			border-left: 1px solid #e8e8e8;
		}
	}

}