/* 골프 예약 변경 신청 */
.pop-golf-change {
	.main-msg {
		margin-bottom: 13px;
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		color: #232323;
	}

	.time-table {
		letter-spacing: -.025em;
		border: 1px solid #ddd;

		h2 {
			height: 35px;
			line-height: 35px;
			text-align: center;
			font-weight: 500;
			font-size: 14px;
			background: #f5f5f5;
			border-bottom: 1px solid #ddd;
		}

		ul {
			height: 200px;
			overflow: scroll;
			overflow-x: hidden;
		}

		a {
			display: block;
			height: 35px;
			line-height: 35px;
			text-align: center;
			font-weight: 300;
			font-size: 14px;
			text-decoration: none;
		}

		.selected a {
			background: #fafafa;
		}
	}
}