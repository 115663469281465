/* 시즌권, 연간이용권 */
.mypage-history-ticket {

	.ticket-main {
		max-width: 400px;
		margin: 0 auto;
		position: relative;

		.ticket-img { width: 100%; }

		.photo {
			width: 50%;
			position: absolute;
			top: 15.5%;
			left: 50%;
			transform: translate(-50%,0);

			.img {
				box-sizing: border-box;
				padding-top: 125%;
				border: 1px solid #ddd;
				position: relative;

				img {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			.btn-area {
				margin-top: 10px;
				text-align: center;
			}
		}

		.info {
			display: flex;
			justify-content: space-between;
			width: 70%;
			font-weight: 500;
			font-size: 18px;
			color: #232323;
			position: absolute;
			bottom: 25%;
			left: 50%;
			transform: translate(-50%,0);
		}

		.bar-code {
			width: 70%;
			position: absolute;
			bottom: 10%;
			left: 50%;
			transform: translate(-50%,0);

			img { width: 100%; }
		}

	}

}