/* 전화번호 안내 */
.customer-tel {

	.tel-list {
		border-bottom: 1px solid #ddd;

		> dt {
			position: relative;
			font-weight: 500;
			color: #232323;
			border-top: 1px solid #ddd;

			a {
				display: block;
				padding: 14px 30px 14px 10px;
				word-wrap: break-word;
				text-decoration: none;

				&:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					right: 10px;
					margin-top: -4px;

					@include sprite-2x($customer-list-off);
				}
			}

			&.active {
				a {
					&:after {
						@include sprite-2x($customer-list-on);
					}
				}
			}
		}

		> dd {
			display: none;
			font-weight: 300;
			font-size: 14px;
			color: #232323;
			background: #f8f8f8;
			border-top: 1px solid #ddd;
			padding: 14px 14px 14px 14px;
			position: relative;
			word-wrap: break-word;
		}
	}

	.tel-data {
		width: 100%;
		table-layout: fixed;
		border: 0;
		border-collapse: collapse;

		th, td {
			padding: 8px 10px;
			text-align: left;
			line-height: 1.3;
			font-weight: 300;
			font-size: 13px;
			color: #232323;
			background: #fff;
			border: 1px solid #ddd;
		}

		th {
			width: 95px;
			font-weight: normal;
		}
	}
}