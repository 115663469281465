/* 현장 포토 스케치 */
.guide-sketch {

	.sketch-detail {
		border-bottom: 1px solid #ddd;

		.detail-header {
			padding: 15px 10px;
			border-top: 1px solid #232323;
			border-bottom: 1px solid #ddd;

			.subject {
				font-weight: 500;
				color: #232323;
			}

			.info {
				font-weight: 300;
				font-size: 12px;
			}

			.cell:not(:first-child):before {
				content: '';
				display: inline-block;
				width: 1px;
				height: 10px;
				margin: 0 8px;
				vertical-align: middle;
				background: #ddd;
				position: relative;
				top: -2px;
			}
		}

		.detail-content {
			padding: 20px 10px;
			font-weight: 300;
			word-wrap: break-word;
		}
	}

	.video-area {
		img { width: 100%; }
	}
}