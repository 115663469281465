/* 찾기 결과 */
.login-find-result {

	/* 결과 설명 - 아이콘 포함 */
	.result-msg {
		text-align: center;
		line-height: 21px;
		font-size: 17px;

		&:before {
			content: '';
			display: block;
			width: 90px;
			height: 90px;
			margin: 0 auto 17px;
			background: url('@@img/login/search-result.png') no-repeat;
			background-size: 90px 90px;
		}
	}

}