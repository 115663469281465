/* 회원권 사용실적  */
.sellinfo-history {	
	.member-num-form {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 15px;
		padding: 15px;
		background: #f5f5f5;
		text-align: center;

		.head {
			flex-shrink: 0;
			margin-right: 10px;
			vertical-align: middle;
			font-weight: 500;
			color: #232323;
		}

		.value {
			display: inline-block;
			vertical-align: middle;

			&:before {
				content: '';
				display: inline-block;
				width: 1px;
				height: 12px;
				margin-right: 10px;
				vertical-align: middle;
				background: #ddd;
				position: relative;
				top: -1px;
			}
		}
	}
}