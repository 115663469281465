/* 직영 및 제휴체인 안내 */
.sellinfo-chain {

	&-list {
		> dt {
			border: 1px solid #ddd;

			&:not(:first-child) { margin-top: 10px; }

			a {
				display: block;
				padding: 14px 15px 14px 15px;
				word-wrap: break-word;
				text-decoration: none;
				position: relative;
	
				&:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					right: 15px;
					right: safe(right,15px);
					margin-top: -6px;
	
					@include sprite-2x($sellinfo-list-off);
				}
			}
	
			&.is-active {
				font-weight: 500;
	
				a {
					&:after {
						@include sprite-2x($sellinfo-list-on);
					}
				}
	
				& + dd {
					display: block;
				}
			}
		}

		> dd {
			display: none;
			padding: 20px 10px;
		}

		.tit {
			color: #232323;
			font-size: 15px;
			font-weight: 500;
		}
	}
}