/* 이용안내 공통 */
.section-guide {
	&.site-pyeongchang .common-basic-info {
		background: #2f4a65;
	}
	&.site-jeju .common-basic-info {
		background: #42a0a9;
	}

	/* 교통안내 - 탑승장안내 링크버튼 */
 	.traffic-link {
		display: inline-block;
		color: #b0895c;
		text-decoration: underline; 

		&:after {
			content: '>';
			display: inline-block;
			margin-left: 3px;
			font-size: 12px;
			vertical-align: 0;
		}
	}
}