/* 휘닉스 블루캐니언 - 소개  */
.waterpark-intro {

	.main-head {
		margin-bottom: 15px;
		font-size: 18px;
	}

	.video-area {
		img { width: 100%; }
	}

	.btn-area {
		margin-top: 20px;
	}
	
}