/* 패키지 상세 */
.package-detail {

	.detail-header {
		padding-bottom: 15px;
		text-align: center;

		.title {
			line-height: 1.3;
			font-weight: 500;
			font-size: 18px;
			color: #232323;
			word-wrap: break-word;
		}

		.info {
			margin-top: 3px;
			font-weight: 300;
			font-size: 13px;
			color: #666;
		}
	}

	.main-img-area {
		img { width: 100%; }
	}

	.package-data {
		padding-bottom: 10px;
		border-bottom: 1px solid #ddd;
	}

	.btn-area {
		text-align: center;
		margin-top: 25px;
	}

	.detail-content {
		padding-bottom: 30px;
		font-weight: 300;
		word-wrap: break-word;

		img { max-width: 100%; }
	}
}