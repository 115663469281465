/* 셔틀버스 */
.guide-traffic-bus {

	.head-title {
		margin-bottom: 15px;
		color: #232323;
		font-size: 18px;
		font-weight: 500;
		text-align: center;
	}

	.next-content { margin-top: 15px; }

}