/* 테라피 */
.experience-therapy {
	
	.site-jeju & .common-basic-info {
		background: #42a0a9;
	}


	.detail-wrap {
		.btn-area {
			margin-top: 15px;
			padding-left: 6px;
		}
	}

}