/* 스쿨 프로그램 안내 */
.snowpark-school-program {

	/* 프리미엄키즈 강습사진 */
	.premium-kids-lesson {
		padding-top: 56.25%;
	}

	/* 혜택 */
	.program-benefit-list {

		.item {
			display: flex;
			justify-content: space-between;
			padding: 20px 20px 20px 0;
			position: relative;
			background: #f5f5f5;
			
			&:not(:first-child) { margin-top: 10px; }
			
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				background: #01609a;
				width: 8px;
				height: 100%;
			}
		}

		.num {
			flex-basis: 48px;
			margin-left: 8px;
			color: #01609a;
			font-size: 16px;
			font-weight: 500;
			text-align: center;
			line-height: 1.4;
		}
		
		.con {
			flex: 1;
		}

		.tit {
			color: #232323;
			font-weight: 500;
		}

		.dot-bu-list li { font-size: 13px; }
	}

	/* 강사진 소개 */
	.instructor-list {
		border: 1px solid #ddd;
		padding: 20px 20px;

		li {
			display: flex;
			align-items: center;

			&:not(:last-child) {
				margin-bottom: 10px;
				padding-bottom: 10px;
				border-bottom: 1px solid #ddd;
			}
		}

		.img {
			flex-basis: 85px;

			img { width: 100%; }
		}

		.info { padding-left: 25px; }

		.name {
			color: #232323;
			font-weight: 500;
		}
		
		.desc {
			margin-top: 5px;
			font-size: 13px;
		}
	}
}