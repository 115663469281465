.guide-map {

	.map-jeju1 {
		padding-top: 86.95%;

		.con { min-width: 1700px; }

		.link {
			display: block;
			height: 2%;
			color: transparent;
			background: url('about:blank');
			position: absolute;
			overflow: hidden;

			// 위치 테스트용
			&.area-test { background-color: rgba(255,0,0,.5); }
		}
	}

	.map-jeju2 {
		padding-top: 72.46%;

		.con { min-width: 1000px; }
	}

	.map-pyeongchang1 {
		padding-top: 86.95%;

		.con { min-width: 900px; }

		.link {
			display: block;
			height: 2.7%;
			color: transparent;
			background: url('about:blank');
			position: absolute;
			overflow: hidden;

			// 위치 테스트용
			&.area-test { background-color: rgba(255,0,0,.5); }
		}
	}

	.map-pyeongchang2 {
		padding-top: 72.46%;

		.con { min-width: 1000px; }
	}

	.map-viewer {
		background: #333;
		position: relative;

		&.is-active {
			.small { display: none; }
			.full { display: block; }
			.btn-toggle:after { @include sprite-2x($ico-zoom-out); }
		}

		.small {
			img {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		.full {
			display: none;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			overflow: auto;
			-webkit-overflow-scrolling: touch;
		}
		

		.con {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			img { width: 100%; }
		}

		.btn-toggle {
			display: block;
			width: 40px;
			height: 0;
			max-height: 40px;
			padding-top: 40px;
			background: rgba(35,35,35,.8);
			position: absolute;
			top: 0;
			right: 0;
			overflow: hidden;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: calc(50% - 7px);
				left: calc(50% - 7px);
				@include sprite-2x($ico-zoom-in);
			}
		}
	}

	.legend {
		display: flex;
		justify-content: space-around;
		padding: 15px 0;
		border-bottom: 1px solid #ddd;

		li {
			font-size: 13px;
			font-weight: 500;
			color: #232323;

			&:before {
				content: '';
				display: inline-block;
				width: 15px;
				height: 15px;
				margin-right: 5px;
				vertical-align: middle;
				border-radius: 8px;
				position: relative;
				top: -2px;
			}

			&.seq1:before { background: #ab7e4a; }
			&.seq2:before { background: #f66808; }
			&.seq3:before { background: #4ec81f; }
			&.seq4:before { background: #29acf7; }
		}
	}

	.legend2 {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding: 15px 4%;
		border-bottom: 1px solid #ddd;

		li {
			padding: 0 10px;
			font-size: 13px;
			font-weight: 500;
			color: #232323;
			white-space: nowrap;

			&:before {
				content: '';
				display: inline-block;
				width: 15px;
				height: 15px;
				margin-right: 5px;
				vertical-align: middle;
				border-radius: 8px;
				position: relative;
				top: -2px;
			}

			&.seq1:before { background: #ab7e4a; }
			&.seq2:before { background: #476fc0; }
			&.seq3:before { background: #31a0e0; }
			&.seq4:before { background: #4ec81f; }
			&.seq5:before { background: #333; }
		}
	}

	.map-title {
		margin-top: 20px;
		margin-bottom: 10px;
		text-align: center;
		font-weight: 500;
		font-size: 16px;
		color: #232323;
	}
}