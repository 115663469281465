/* 골프 공통 */
.section-golf {
	.common-basic-info {
		background: #2f4a65;
	}

	.tab-page-scroll + .common-photo-slider {
		margin-top: -20px;
	}

	.main-title {
		margin-bottom: 10px;
		text-align: center;
		line-height: 1.2;
		font-weight: 500;
		font-size: 18px;
		color: #232323;
		letter-spacing: -.05em;
	}
}