/* 현장속보 */
.snowpark-news {

	.news-list {
		border-bottom: 1px solid #e8e8e8;

		.no-item {
			padding: 50px 0;
			text-align: center;
			border-top: 1px solid #e8e8e8;
		}

		.item {
			border-top: 1px solid #e8e8e8;
		}

		.link {
			display: flex;
			padding: 10px 0;
			text-decoration: none;
		}

		.ico {
			flex-shrink: 0;
			width: 35px;
			height: 35px;
			margin: 0 10px;

			img { width: 100%; }
		}

		.con-wrap {
			flex: 1;
			overflow: hidden;
		}

		.title {
			display: block;
			width: 100%;
			font-weight: 500;
			font-size: 14px;
			color: #232323;
			@include ellipsis;
		}

		.con {
			font-size: 12px;
		}
	}

	.news-detail {
		border-top: 1px solid #232323;
		border-bottom: 1px solid #ddd;

		.detail-header-table {
			table {
				width: 100%;
				table-layout: fixed;
				border: 0;
			}

			th, td {
				box-sizing: border-box;
				height: 40px;
				padding: 5px;
				text-align: left;
				font-weight: 500;
				border: 0;
				border-bottom: 1px solid #ddd;
			}

			th {
				font-size: 13px;
				color: #666;
			}

			td {
				font-size: 14px;
				color: #232323;
			}

			.ico-weather {
				width: 30px;
				height: 30px;
			}
		}

		.detail-content {
			padding: 20px 10px;
			word-wrap: break-word;
		}
	}
	
}