/* 로그인 폼 */
.login-form {

	/* 로그인 박스 */
	.login-main-form {
		box-sizing: border-box;
		margin-top: -#{$com-mt};
		padding: 30px 15px;
		padding: 30px safe(left,15px);
		letter-spacing: -.05em;
		background: #f5f5f5;
		position: relative;
	}

	.input-row {
		@include clearfix;

		+ .input-row { margin-top: 5px; }

		label { @include hidden; }
	}

	.input {
		float: right;
		box-sizing: border-box;
		display: block;
		width: 100%;
		height: 40px;
		line-height: 38px;
		padding: 0 14px;
		font-size: 14px;
		color: #555;
		background: #fff;
		border: 1px solid #ddd;
		border-radius: 0;
	}

	.option-row {
		margin-top: 10px;
		font-weight: 300;
		font-size: 14px;
		color: #232323;
	}

	.btn-submit {
		box-sizing: border-box;
		display: block;
		width: 100%;
		height: 40px;
		line-height: 40px;
		margin-top: 20px;
		font-weight: 500;
		font-size: 15px;
		color: #fff;
		background: #232323;
		border: 0;
	}

	.action {
		box-sizing: border-box;
		margin: 20px 0 0;

		@include clearfix;
	}

	.btn-find {
		box-sizing: border-box;
		float: left;
		width: calc(50% - 10px);
		height: 35px;
		line-height: 33px;
		text-align: center;
		font-weight: 300;
		font-size: 14px;
		color: #232323;
		text-decoration: none;
		border: 1px solid #232323;

		& + .btn-find { float: right; }
	}

	.action2 {
		padding: 30px 0 35px;
		text-align: center;

		.msg {
			line-height: 1.3;
			font-size: 15px;
		}

		.btn-area { margin-top: 12px; }

		.btn-join { width: 130px; }
	}
}