/* 통합예약 날짜검색 메인 */
.reservation-main {
	.category-select {
		margin-bottom: 10px;
		select { text-align-last: center; font-weight: 500; }
	}

	.date-pick-area {
		background: #f5f5f5 url('@@img/reservation/from-to.png') no-repeat 50% 50% / 29px 10px;

		.btn-block {
			display: flex;
			padding: 18px 20px;
			text-decoration: none;
		}

		dl {
			flex: 1;
			display: flex;
			flex-direction: column;
			width: 50%;
			text-align: center;
		}

		dt {
			padding-bottom: 7px;
			font-weight: 500;
			font-size: 13px;
			color: #b0895c;
		}

		dd {
			display: flex;
			flex-direction: column;
		}

		.year-month {
			font-size: 14px;
			letter-spacing: 0;
		}

		.day {
			line-height: 1;
			font-weight: 500;
			font-size: 40px;
			color: #232323;
			letter-spacing: 0;
		}

		.day-of-week {
			padding-top: 3px;
			font-weight: 500;
			font-size: 14px;
			color: #232323;
		}
	}

	.btn-area {
		margin-top: 15px;
	}

	.btn-search {
		font-size: 16px;

		.search {
			display: inline-block;
			margin-right: 5px;
			vertical-align: middle;
			position: relative;
			top: -1px;

			@include sprite-2x($reservation-search);
		}
	}
}