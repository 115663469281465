/* 스쿨 안내 */
.snowpark-school-info {

	.process-list { margin-top: 10px; }

	.tab-con > .detail-wrap:first-child { padding-top: 15px; }
	
	.video-area {
		img { width: 100%; }
	}

}