/* 행사웨딩 문의안내 */
.party-contact {
	.data {
		display: flex;
		padding: 0 5px;
		font-size: 13px;

		dt {
			flex-shrink: 0;
			width: 50px;
		}

		dd {
			flex: 1;
		}
	}
}