/* 이용시설별 안내, 메뉴별안내 리스트 */
.eating-guide {
	.category-select {
		margin-bottom: 15px;
		select { border-color: #232323; }
	}

	.facilities-list {

		.item {
			display: flex;
			border: 1px solid #ddd;

			&:not(:first-child) { margin-top: 10px; }

			> a {
				flex: 1;
				display: flex;
				box-sizing: border-box;
				padding: 10px 20px 10px 10px;
				text-decoration: none;
				position: relative;

				&:after {
					content: '';
					display: block;
					position: absolute;
					top: calc(50% - 6px);
					right: 12px;
					@include sprite-2x($ico-arw-link);
				}
			}
		}

		.info {
			flex: 1;
		}

		.thumb {
			flex-shrink: 0;
			width: 90px;
			height: 90px;
			margin-right: 15px;
			position: relative;
			
			img { @include absFull; }
		}
		
		.tit {
			font-weight: 500;
			font-size: 16px;
			color: #232323;

			span {
				font-size: 14px;
				color: #666;
			}
		}

		.data {
			margin-top: 8px;
			line-height: 1.4;
			text-indent: -77px;
			padding-left: 75px;
			font-size: 13px;
			color: #666;
		}

		.sub-tit {
			box-sizing: border-box;
			display: inline-block;
			width: 75px;
			padding-left: 7px;
			font-weight: 500;
			text-indent: 0;
			position: relative;

			&:before {
				content: '';
				width: 2px;
				height: 2px;
				background: #666;
				position: absolute;
				top: .6em;
				left: 0;
			}
		}
	}
}