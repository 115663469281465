/* 연회 */
.party-banquet {
	margin: -#{$com-mt} 0 0;

	/* 도면보기 */
	.plan-content {
		.head-sub2 span { font-weight: 400; }
		
		.btn-area {
			margin: 35px 0 15px;
			text-align: center;

			.btn { width: 190px; }
		}
	}

	.floor-plan-list {
		li {
			display: flex;
			align-items: center;
			&:not(:first-child) { margin-top: 10px; }
		}
		
		span:nth-child(1) {
			flex: 1;
			img { width: 100%; }
		}
		span:nth-child(2) {
			width: 80px;
			padding-left: 20px;
			font-size: 13px;
		}
	}

	/* 하단 버튼영역 */
	.foot-btn-area {
		padding: 30px 0;
		background: #f5f5f5;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		.btn {
			width: 190px;
			&:not(:first-child) { margin-top: 10px; }
		}
	}

	/* 평창 - 연회 */
	.photo-detail-info {
		margin-left: $com-ml;
		margin-left: safe(left,$com-ml);
		margin-right: $com-mr;
		margin-right: safe(right,$com-mr);
	}

	.head-sub .small { font-size: 12px; }
}