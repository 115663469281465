/* 이용안내 - 휘닉스 제주 소개 */
.guide-intro-jeju {
	margin-bottom: -#{$com-mb};

	.intro-wrap {
		margin: 30px 0;

		.con {
			margin: 10px 0;
			text-align: center;
		}
	}

	.top-intro {
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: 1px solid #ddd;
	}

	.head-copy {
		margin-bottom: 20px;
		text-align: center;
		line-height: 1.4;
		font-weight: 500;
		font-size: 16px;
		color: #232323;
	}

	.video-area {
		margin-bottom: 20px;
		img { width: 100%; }
	}

	.sub-visual {
		margin: 30px -#{$com-ml} 20px;
		margin: 30px safe(left,-$com-ml) 20px;
		padding-top: 33.33%;
		background: url('@@img/guide/jeju/intro/sub-visual.jpg');
		background-size: cover;
	}

	.architect {
		margin: 0 -#{$com-ml};
		margin: 0 safe(left,-$com-ml);
		padding: 30px $com-ml;
		padding: 30px safe(left,$com-ml);
		background: #eaebed;

		.list {
			li {
				display: flex;
				font-size: 13px;

				&:not(:first-child) {
					margin-top: 15px;
					padding-top: 15px;
					border-top: 1px solid #d9d8d6;
				}
			}

			img {
				flex-shrink: 0;
				width: 100px;
				height: 100px;
				margin-right: 20px;
			}
		}
	}
}