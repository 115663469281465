/* 시즌권 지문인증 */
.snowpark-auth {

	.notice-msg {
		margin: -#{$com-mt} -#{$com-ml} 0;
		margin: -#{$com-mt} safe(left,-$com-ml) 0;
		padding: 35px 25px 35px 118px;
		padding: 35px safe(right,25px) 35px safe(left,118px);
		background: #f5f5f5 url('@@img/snowpark/bg-fingerprint.png') no-repeat;
		background-position: 22px center;
		background-position: safe(left,22px) center;
		background-size: 75px 75px;
		color: #232323;
	}

	.auth-process-list {
		.item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border: 1px solid #ddd;
			position: relative;

			&:after {
				display: block;
				content: '';
				position: absolute;
				left: calc(50% - 8px);
				bottom: -16px;
				width: 15px;
				height: 10px;
				background: url('@@img/snowpark/arrow-bottom.png') no-repeat;
				background-size: cover;
			}

			&:not(:first-child) { margin-top: 20px; }
			&:last-child:after { display: none; }
		}

		.num {
			width: 50px;
			text-align: center;

			img { width: 20px; }
		}
		.view {
			padding: 20px 0 20px 20px;
			border-left: 1px dashed #d9d8d6;
		
			img { width: 90px; }
		}
		.desc {
			flex: 1;
			padding: 0 20px;
			text-align: center;
		}
	}
}
