/* 건축아트 글라스하우스 */
.architecture-glass-house {
	.common-basic-info {
		background: #42a0a9;
	}

	.main-info {
		padding: 25px 0 20px;

		.title {
			margin-bottom: 7px;
			line-height: 1.3;
			font-size: 18px;
			color: #232323;
			letter-spacing: -.05em;
		}
	}

	.architect-intro {
		margin-top: 20px;
	}
}