/* 변경 */
.login-find-change {

	.check-wrap {
		@include clearfix;

		.txt { float: left; }
		.txt + div { float: right; }
	}

	.foot-info {
		margin-top: 45px;
		text-align: center;

		.txt {
			margin-bottom: 12px;
			line-height: 1.3;
			font-size: 15px;
		}

		.btn {
			width: 100px;
		}
	}

	.layout-form {
		margin: 0 -15px;
		padding: 25px 15px;
		padding: 25px safe(left,15px);
		background: #f5f5f5;
	}
}