/* 메인 썸네일 목록2 */
.main-thumb-list2 {
	box-sizing: border-box;
	width: 100%;
	padding: 0 0 30px;
	position: relative;
	overflow: hidden;

	.list {
		box-sizing: border-box;
		display: flex;
		width: 100%;
		height: 100%;
	}

	.item {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		width: 100%;
		padding: 0 $com-ml;
		padding: 0 safe(left,$com-ml);
	}

	.link {
		display: flex;
		flex-direction: column;
		text-decoration: none;
		position: relative;
	}

	.thumb {
		padding-top: 80%;
		position: relative;
		overflow: hidden;
	}

	.img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		vertical-align: top;
	}

	.label {
		display: block;
		width: 104px;
		height: 28px;
		line-height: 28px;
		text-align: center;
		font-weight: 500;
		font-size: 15px;
		color: #fff;
		letter-spacing: 0;
		background: #002d4d;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.con {
		padding: 25px 0 15px;
		text-align: center;
	}

	.title {
		line-height: 1.2;
		font-weight: 500;
		font-size: 20px;
		color: #fff;
		letter-spacing: -.03em;

		@include ellipsis;
	}

	.info {
		line-height: 19px;
		margin-top: 12px;
		font-size: 14px;
		color: #c0c0c0;
		letter-spacing: -.05em;
		word-wrap: break-word;
	}

	.action {
		margin-top: 15px;
	}

	.btn-link {
		box-sizing: border-box;
		display: inline-block;
		width: 36%;
		height: 35px;
		line-height: 33px;
		vertical-align: middle;
		text-align: center;
		font-size: 14px;
		color: #c0c0c0;
		text-decoration: none;
		letter-spacing: 0;
		border: 1px solid #959595;
		border-radius: 18px;

		& + .btn-link {
			margin-left: 5px;
		}
	}

	.prevnext {
		width: 100%;
		padding-top: 74%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.btn-prev {
		display: block;
		background: rgba(0,0,0,.8);
		position: absolute;
		top: calc(50% - 18px);
		left: 0;

		@include afterSpriteIR-2x($main-slider-prev,35px,35px,absolute);
	}

	.btn-next {
		display: block;
		background: rgba(0,0,0,.8);
		position: absolute;
		top: calc(50% - 18px);
		right: 0;

		@include afterSpriteIR-2x($main-slider-next,35px,35px,absolute);
	}
}