.pop-video-view {
	.common-pop-content {
		padding: 0;
	}
	.video-area {
		padding-top: 56.25%;
		position: relative;

		iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}