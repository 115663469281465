/* 교통 안내 */
.guide-traffic-map-pyeongchang {
	margin: 0 $com-ml -#{$com-mb};
	margin: 0 safe(left,$com-ml) -#{$com-mb};

	.map-area {
		margin: -20px -#{$com-ml};
		margin: -20px safe(left,-$com-ml);
	}
	
	.head-title {
		margin: 30px 0 15px;
		color: #232323;
		font-size: 18px;
		font-weight: 500;
		text-align: center;
	}

	.traffic-content {
		.btn-area {
			margin-top: 20px;
			text-align: center;

			.btn + .btn { margin-left: 4px; }
		}
	}

	.car-navi {
		.desc {
			padding: 15px;
			border: 1px solid #e4e4e4;
		}

		.list {
			margin-top: 25px;

			li {
				padding-left: 25px;
				position: relative;

				&:not(:first-child) { margin-top: 18px; }
			}
			
			.title {
				color: #006097;
				font-weight: 500;
			}

			.num {
				display: inline-block;
				width: 18px;
				height: 18px;
				line-height: 18px;
				text-align: center;
				font-size: 8px;
				color: #fff;
				background: #232323;
				border-radius: 100%;
				position: absolute;
				top: 2px;
				left: 0;
			}
		}
	}

	.ktx-route {		
		padding-top: 80%;
	}
} 