/* 골프 - 이용안내 */
.golf-guide {

	.info-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		li {
			width: calc((100% - 20px) / 2);
			text-align: center;

			&:nth-child(2) ~ li { margin-top: 25px; }
		}

		.img {
			padding-top: 56.25%;
			background-size: cover;
		}

		.con-wrap {
			min-height: 40px;
			margin-top: 5px;
		}

		.title {
			font-weight: 500;
			color: #232323;
		}

		.con {
			font-size: 12px;
		}

		.btn-area {
			margin-top: 10px;
		}
	}

}