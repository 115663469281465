/* 공지사항 */
.customer-notice {

	/* 공지사항 목록 */
	.notice-list {
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;

		.no-item {
			padding: 50px 0;
			text-align: center;
		}

		.item {
			&:not(:first-child) {
				border-top: 1px solid #ddd;
			}

			&.strong {
				background: #f5f5f5;
			}
		}

		.link {
			display: flex;
			padding: 9px 10px 8px;
			text-decoration: none;
		}

		.flag {
			box-sizing: border-box;
			flex-shrink: 0;
			width: 35px;
			font-weight: 500;
			font-size: 12px;
			color: #b0895c;

			&.type1 { color: #006097; }
			&.type2 { color: #d8671a; }
			&.type3 { color: #dd163d; }
		}

		.con {
			flex: 1 0;
			overflow: hidden;
		}

		.title {
			line-height: 1.3;
			font-weight: 500;
			color: #232323;
			word-wrap: break-word;
		}

		.info {
			font-weight: 300;
			font-size: 12px;
		}
	}

	/* 공지사항 상세 */
	.notice-detail {
		border-bottom: 1px solid #ddd;
		
		.detail-header {
			padding: 15px 10px;
			border-top: 1px solid #232323;
			border-bottom: 1px solid #ddd;

			.subject {
				font-weight: 500;
				color: #232323;
			}

			.info {
				font-weight: 300;
				font-size: 12px;
			}
		}

		.detail-content {
			padding: 20px 10px;
			word-wrap: break-word;
		}
	}
}