/* 연회 - 기자재 대여표 */
.pop-party-rental {

	.contact {
		margin: 0 -15px -25px;
		padding: 20px 0;
		background: #f5f5f5;
		color: #232323;
		font-size: 13px;
		font-weight: 500;
		text-align: center;
		line-height: 20px;

		&:before {
			content: '';
			display: inline-block;
			width: 20px;
			height: 20px;
			margin-right: 8px;
			background: url('@@img/party/jeju/ico-contact.png');
			background-size: cover;
			vertical-align: -4px;
		}
	}

}