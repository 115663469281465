/* 슬로프/리프트 */
.snowpark-slope-lift {

	.slope-img {
		margin: -20px -#{$com-ml} 0;
		margin: -20px safe(left,-$com-ml) 0;
		padding-top: 80%;
		background-size: cover;
	}

	.intro-wrap {
		margin: 30px 0;

		.head-copy {
			margin: 0 0 10px;
			line-height: 1.3;
			font-weight: 500;
			font-size: 18px;
			color: #232323;
		}

		.con { margin: 10px 0; }
	}

	.slope-info {
		box-sizing: border-box;
		height: 345px;
		margin: 0 -#{$com-ml} -#{$com-mb};
		margin: 0 safe(left,-$com-ml) -#{$com-mb};
		padding: 30px 11.4%;
		// padding: 30px safe(left,11.4%);
		text-align: center;
		color: #fff;
		background: url('@@img/snowpark/bg-slope-info.jpg') no-repeat 50% 50% / cover;

		.title {
			margin-bottom: 11px;
			font-weight: 500;
			font-size: 16px;
			letter-spacing: -.05em;
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			height: 240px;
			border: 1px solid #fff;
		}

		li {
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 50%;
			height: 50%;
			font-size: 12px;

			&:nth-child(even) { border-left: 1px solid #fff; }
			&:nth-child(2) ~ li { border-top: 1px solid #fff; }
		}

		.up {
			font-weight: 500;
			font-size: 19px;
		}

		.unit {
			font-size: 16px;
		}
	}

	.state-open { font-weight: 500; color: $point2; }
	.state-close { font-weight: 500; color: #232323; }

}