/* 식음 호텔 상세 */
.eating-detail-hotel {
	margin: -#{$com-mt} 0 -#{$com-mb};
	
	.detail-wrap {
		margin-left: $com-ml;
		margin-left: safe(left,$com-ml);
		margin-right: $com-mr;
		margin-right: safe(right,$com-mr);
	}
}